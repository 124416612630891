/* storybook-check-ignore */
import { Box, ChevronBackButton, FilterButton } from '@opendoor/bricks-next';

import { AddressSearchResultFragment } from '__generated__/athena';

import { MapLocationSearch } from 'components/marketplace/map/LocationSearch';

import { MapLocation } from './types';

interface MobileHeaderFiltersProps {
  isFocused: boolean;
  selectedLocation?: MapLocation;
  onBack: () => void;
  onOpenFilters: () => void;
  setSelectedLocation: (location?: MapLocation | null) => void;
  selectedAddress?: AddressSearchResultFragment;
  setSelectedAddress: (address?: AddressSearchResultFragment | null) => void;
}

export const MobileHeaderFilters = ({
  isFocused,
  selectedLocation,
  setSelectedLocation,
  onBack,
  onOpenFilters,
  selectedAddress,
  setSelectedAddress,
}: MobileHeaderFiltersProps) => {
  return (
    <Box flexDirection="column">
      <MapLocationSearch
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
        setSelectedLocation={setSelectedLocation}
        selectedLocation={selectedLocation}
        isFocused={isFocused}
      />
      <Box
        position="absolute"
        backgroundColor="$backgroundPrimary"
        top={10}
        right="5%"
        zIndex={10000}
      >
        <FilterButton
          size="small"
          analyticsName="cosmos-marketplace-map-header-filter-button"
          onPress={onOpenFilters}
        />
      </Box>
      <Box
        position="absolute"
        backgroundColor="$backgroundPrimary"
        top={10}
        left="2%"
        zIndex={10000}
        display={isFocused ? 'flex' : 'none'}
      >
        <ChevronBackButton
          size="small"
          analyticsName="cosmos-marketplace-map-location-search-back-button"
          onPress={onBack}
        />
      </Box>
    </Box>
  );
};
