/* storybook-check-ignore */
import { useEffect, useMemo, useRef, useState } from 'react';

import {
  Box,
  PressableBox,
  PressableBoxProps,
  Text,
  TextInput,
  TextInputProps,
} from '@opendoor/bricks-next';
import { formatCurrency } from '@opendoor/bricks/helpers/currencyHelpers';
import debounce from 'lodash/debounce';

import {
  AddressSearchResultFragment,
  OdProtosGeoDataStoreData_Zone_Type,
} from '__generated__/athena';

import { getAthenaClient } from 'components/api';

import { useOutsideCaller } from '../helpers/useOutsideCaller';
import { MapLocation } from './types';

const formatZoneType = (zoneType?: OdProtosGeoDataStoreData_Zone_Type): string => {
  switch (zoneType) {
    case 'CITY':
      return '(City)';
    case 'COUNTY':
      return '(County)';
    case 'ZIP_CODE':
      return '(Zip code)';
    case 'NEIGHBORHOOD':
    case 'SUBNEIGHBORHOOD':
    case 'MACRO_NEIGHBORHOOD':
      return '(Neighborhood)';
    default:
      return '';
  }
};

export interface Location {
  label: string;
  zoneType: string;
  value: string;
  coordinates: [number, number];
}

export interface LocationSearchProps {
  isFocused: boolean;
  selectedLocation?: MapLocation;
  setSelectedLocation: (location?: MapLocation | null) => void;
  selectedAddress?: AddressSearchResultFragment;
  setSelectedAddress: (address?: AddressSearchResultFragment | null) => void;
}

const QUERY_STRING_MIN_LENGTH = 2;

export const MapLocationSearch = ({
  isFocused,
  selectedAddress,
  setSelectedAddress,
  selectedLocation,
  setSelectedLocation,
}: LocationSearchProps) => {
  const [locationOptions, setLocationOptions] = useState<Array<MapLocation> | undefined>(
    selectedLocation ? [selectedLocation] : undefined,
  );
  const [addressOptions, setAddressOptions] = useState<
    Array<AddressSearchResultFragment> | undefined
  >();
  const [query, setQuery] = useState<string | undefined>();
  const [isLocationFocused, setIsLocationFocused] = useState(false);
  const [isDropdownFocused, setIsDropdownFocused] = useState(false);
  const containerRef = useRef<HTMLElement | null>(null);

  const debouncedSearchLocations = useMemo(() => {
    const fetchLocations = async (queryStr?: string) => {
      if (queryStr && queryStr.length > QUERY_STRING_MIN_LENGTH) {
        const types: OdProtosGeoDataStoreData_Zone_Type[] = queryStr.match(/^\d/)
          ? ['ZIP_CODE']
          : ['CITY', 'NEIGHBORHOOD', 'SUBNEIGHBORHOOD'];
        const locationsResp = await getAthenaClient().SearchLocations({ query: queryStr, types });
        const addressResp = await getAthenaClient().AddressSearch({ query: queryStr });
        const zones = locationsResp?.geoDataStore?.searchZonesByTextQuery?.zones
          ?.map((result) => {
            const { zone } = result;
            return {
              label: zone?.name + ', ' + zone?.stateAbbr,
              zoneType: formatZoneType(zone?.zoneType),
              value: zone?.uuid,
              coordinates: zone?.centroid ? JSON.parse(zone?.centroid)?.coordinates : [0, 0],
              population: zone?.population,
            } as MapLocation;
          })
          .sort((a, b) => {
            if (a.population && b.population) {
              return b.population - a.population;
            }
            return 0;
          });
        setLocationOptions(zones);
        setAddressOptions(addressResp?.marketplace?.addressSearch?.properties || []);
      } else {
        setLocationOptions(undefined);
        setAddressOptions(undefined);
      }
    };
    return debounce(fetchLocations, 500);
  }, []);

  useEffect(() => {
    debouncedSearchLocations(query);
    return () => debouncedSearchLocations.cancel();
  }, [query, debouncedSearchLocations]);

  useOutsideCaller(() => {
    setIsLocationFocused(false);
    setIsDropdownFocused(false);
  }, [containerRef]);

  const handleLocationSearch: TextInputProps['onChangeText'] = (search) => {
    setQuery(search);
  };

  const handleLocationFocus = () => {
    setIsLocationFocused(true);
    setQuery('');
    setSelectedLocation(undefined);
    setSelectedAddress(undefined);
  };
  const handleDropdownFocus = () => setIsDropdownFocused(true);
  const handleDropdownBlur = () => {
    setIsLocationFocused(false);
    setIsDropdownFocused(false);
  };

  const handleLocationSelect: PressableBoxProps['onPress'] = (e) => {
    if (!(e.target instanceof HTMLElement)) {
      return;
    }
    if (e.target.dataset.location) {
      const selectedLoc = JSON.parse(e.target.dataset.location);
      setSelectedLocation(selectedLoc);
      setSelectedAddress(undefined);
      handleDropdownBlur();
      handleLocationSearch('');
    }
  };

  const handleAddressSelect: PressableBoxProps['onPress'] = (e) => {
    if (!(e.target instanceof HTMLElement)) {
      return;
    }
    if (e.target.dataset.addresstoken) {
      const selectedAddr = addressOptions?.find(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (addr) => addr.addressToken === e?.target?.dataset?.addresstoken,
      );
      if (selectedAddr) {
        setSelectedAddress(selectedAddr);
        setSelectedLocation(undefined);
        handleDropdownBlur();
        handleLocationSearch('');
      }
    }
  };

  const shouldShowDropdown = useMemo(
    () =>
      query &&
      (locationOptions?.length || addressOptions?.length) &&
      (isLocationFocused || isDropdownFocused),
    [query, locationOptions, addressOptions, isLocationFocused, isDropdownFocused],
  );

  return (
    <Box flexDirection="column" width="100%" ref={containerRef}>
      <Text
        tag="label"
        typography="$labelMedium"
        color="$contentPrimary"
        mb={12}
        $smallerThanMD={{ display: 'none' }}
      >
        Location
      </Text>
      <TextInput
        ariaLabel="Location search"
        id="locations-search"
        data-testid="location-search-input"
        name="locations"
        size="small"
        $smallerThanMD={{
          size: 'xsmall',
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          width: '85%',
        }}
        ml={isFocused ? 20 : 0}
        placeholder="City, address, zip"
        onChangeText={handleLocationSearch}
        onFocus={handleLocationFocus}
        prefilledValue={selectedAddress?.fullAddress || selectedLocation?.label}
        key={selectedAddress?.fullAddress || selectedLocation?.label}
      />
      <Box id="locations" width="100%" key={`location-results-${query}`}>
        <Box
          zIndex={10000}
          backgroundColor="$backgroundPrimary"
          position="absolute"
          height={25}
          display={shouldShowDropdown ? 'flex' : 'none'}
          width="100%"
          borderStyle="solid"
          borderColor="$borderAccentBrand"
          borderTopWidth={0}
          borderLeftWidth={1}
          borderRightWidth={1}
          borderBottomWidth={0}
          left={0}
          top={-15}
          onFocus={handleDropdownFocus}
          onBlur={handleDropdownBlur}
        />
        <Box
          position="absolute"
          backgroundColor="$backgroundPrimary"
          zIndex={10000}
          height={400}
          display={shouldShowDropdown ? 'flex' : 'none'}
          width="100%"
          borderStyle="solid"
          borderWidth={1}
          borderBottomLeftRadius="$8x"
          borderLeftWidth={1}
          borderBottomRightRadius="$8x"
          borderTopWidth={0}
          borderColor="$borderAccentBrand"
          left={0}
          top={5}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          overflowY="scroll"
          overflowX="hidden"
          onFocus={handleDropdownFocus}
          onBlur={handleDropdownBlur}
        >
          {query && locationOptions?.length && locationOptions.length > 0 ? (
            <Box flexDirection="column" width="100%" backgroundColor="$backgroundPrimary">
              <Text tag="p" typography="$labelSmall" ml={20}>
                Locations
              </Text>
              {locationOptions?.map((item, index) => (
                <Box
                  key={item.label}
                  borderBottomWidth={1}
                  borderBottomColor="$borderSecondary"
                  alignItems="flex-start"
                  hoverStyle={{
                    backgroundColor: '$backgroundSecondary',
                  }}
                >
                  <PressableBox
                    aria-label={`Select ${item.label}`}
                    data-testid={`search-result-${item.value}`}
                    analyticsName="comos-map-select-location"
                    onPress={handleLocationSelect}
                    position="relative"
                    px={20}
                    py={20}
                    mt={index === 0 ? 10 : 0}
                    flexDirection="row"
                    pressStyle={{
                      backgroundColor: '$backgroundSecondary',
                    }}
                  >
                    <Text tag="span" typography="$bodySmall" data-location={JSON.stringify(item)}>
                      {item.label} {item.zoneType}
                    </Text>
                  </PressableBox>
                </Box>
              ))}
            </Box>
          ) : null}
          {query && addressOptions && addressOptions?.length > 0 ? (
            <Box flexDirection="column" width="100%" backgroundColor="$backgroundPrimary">
              <Text tag="p" mt={24} typography="$labelSmall" ml={20}>
                Listings
              </Text>
              {addressOptions?.map((item, index) => (
                <Box
                  key={item.fullAddress}
                  borderBottomWidth={1}
                  borderBottomColor="$borderSecondary"
                  alignItems="flex-start"
                  hoverStyle={{
                    backgroundColor: '$backgroundSecondary',
                  }}
                >
                  <PressableBox
                    aria-label={`Select ${item.fullAddress}`}
                    analyticsName="cosmos-map-select-address"
                    onPress={handleAddressSelect}
                    position="relative"
                    px={20}
                    py={20}
                    mt={index === 0 ? 10 : 0}
                    flexDirection="row"
                    pressStyle={{
                      backgroundColor: '$backgroundSecondary',
                    }}
                  >
                    <Box flexDirection="column" alignItems="flex-start">
                      <Text
                        tag="p"
                        typography="$bodySmall"
                        width="100%"
                        data-addresstoken={item.addressToken}
                      >
                        {item.fullAddress}
                      </Text>
                      {item.priceCents && (
                        <Text
                          tag="p"
                          typography="$labelXsmall"
                          alignSelf="flex-start"
                          data-addresstoken={item.addressToken}
                        >
                          {formatCurrency(item.priceCents / 100)}
                        </Text>
                      )}
                    </Box>
                  </PressableBox>
                </Box>
              ))}
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};
