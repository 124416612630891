/* storybook-check-ignore */
import { useState } from 'react';

import { Box, Button, CloseButton, Text, TextButton } from '@opendoor/bricks-next';

import { SearchFilters, SearchFiltersProps } from 'components/marketplace/search/SearchFilters';

interface MapSearchFiltersProps extends SearchFiltersProps {
  onClose?: () => void;
  onApplyFilters?: () => void;
  onResetFilters?: () => void;
  isLoading: boolean;
}

export const MapSearchFilters = (props: MapSearchFiltersProps) => {
  // This reset filter key thing is a hack because <Dropdown /> and its
  // underlying components don't support being controlled. They only
  // support being managed, so resetting filters doesn't work as expected.
  // Instead, we'll pass this as the key to the <SearchFilters /> component
  // to force it to re-render and reset the filters.
  const [resetFiltersKey, setResetFiltersKey] = useState(0);
  const handleResetFilters = () => {
    setResetFiltersKey((prev) => prev + 1);
    props.onResetFilters?.();
  };

  return (
    <Box
      width="100%"
      flexDirection="column"
      height="100%"
      backgroundColor="$backgroundPrimary"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      overflowY="scroll"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      overflowX="none"
      $largerThanMD={{
        ml: 0,
        width: '100%',
      }}
      $smallerThanLG={{
        width: '100%',
        backgroundColor: '$backgroundPrimary',
      }}
      data-testid="map-search-filters"
    >
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="baseline"
        my={12}
        mx={36}
        $smallerThanLG={{ mx: 12 }}
      >
        <Text tag="p" typography="$labelLarge" mb={24} $largerThanMD={{ display: 'none' }}>
          Filters
        </Text>
        <CloseButton
          analyticsName="close-property-search-filters"
          data-testid="close-property-search-filters"
          onPress={props.onClose}
          size="large"
          variant="default"
          p={10}
          $largerThanMD={{ p: 0, ml: -10 }}
        />
      </Box>
      <Box mt={24} mx={36} $smallerThanLG={{ mx: 24 }}>
        <SearchFilters {...props} key={resetFiltersKey} />
      </Box>
      <Box
        flexDirection="row"
        mx={36}
        $smallerThanLG={{ flexDirection: 'column-reverse', mx: 24 }}
        justifyContent="space-between"
        alignItems="center"
        my={20}
      >
        <TextButton
          analyticsName="property-search-filters-reset"
          label="Reset filters"
          onPress={handleResetFilters}
          size="medium"
          variant="primary"
          width="20%"
          $smallerThanLG={{ width: '100%', mt: 10, size: 'xsmall' }}
        />
        <Button
          analyticsName="property-search-filters"
          label="Search"
          onPress={props.onApplyFilters}
          size="medium"
          variant="primary"
          width="20%"
          loading={props.isLoading}
          $smallerThanLG={{ width: '100%', size: 'small' }}
          disabled={props?.errors ? Object.keys(props.errors).length > 0 : false}
        />
      </Box>
    </Box>
  );
};
