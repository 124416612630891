/* storybook-check-ignore */
import { Box, Button, Dropdown } from '@opendoor/bricks-next';

import { LocationSearchProps, MapLocationSearch } from 'components/marketplace/map/LocationSearch';

interface QuickFiltersProps {
  bathrooms: string;
  bedrooms: string;
  onFilterChange: (beds: string, baths: string) => void;
  isLoadingFilter: boolean;
  onFiltersButtonClick: () => void;
}

export const QuickFilters = ({
  bathrooms,
  bedrooms,
  onFilterChange,
  isLoadingFilter,
  setSelectedAddress,
  selectedAddress,
  setSelectedLocation,
  selectedLocation,
  onFiltersButtonClick,
  isFocused,
}: QuickFiltersProps & LocationSearchProps) => {
  const handleOnBedroomsChange = (value: string) => {
    onFilterChange(value, bathrooms);
  };

  const handleOnBathroomsChange = (value: string) => {
    onFilterChange(bedrooms, value);
  };

  return (
    <Box justifyContent="space-between" flexDirection="row" width="100%" my={25} gap={20}>
      <Box flex={2} width="40%">
        <MapLocationSearch
          isFocused={isFocused}
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
          setSelectedLocation={setSelectedLocation}
          selectedLocation={selectedLocation}
        />
      </Box>
      <Box $smallerThanLG={{ display: 'none' }} flex={1}>
        <Dropdown
          id="bedrooms"
          name="bedrooms"
          prefilledValue={bedrooms?.toString() || undefined}
          options={[
            {
              label: '1+',
              value: '1',
            },
            {
              label: '2+',
              value: '2',
            },
            {
              label: '3+',
              value: '3',
            },
            {
              label: '4+',
              value: '4',
            },
          ]}
          placeholderOption={{
            label: '0',
            value: undefined,
          }}
          size="small"
          title="Beds"
          onValueChange={handleOnBedroomsChange}
        />
      </Box>
      <Box $smallerThanLG={{ display: 'none' }} flex={1}>
        <Dropdown
          id="bathrooms"
          name="bathrooms"
          prefilledValue={bathrooms?.toString() || undefined}
          options={[
            {
              label: '1+',
              value: '1',
            },
            {
              label: '2+',
              value: '2',
            },
            {
              label: '3+',
              value: '3',
            },
            {
              label: '4+',
              value: '4',
            },
          ]}
          placeholderOption={{
            label: '0',
            value: undefined,
          }}
          size="small"
          title="Baths"
          onValueChange={handleOnBathroomsChange}
        />
      </Box>
      <Button
        analyticsName="filter-property-search"
        data-testid="filter-property-search"
        label="Filters"
        onPress={onFiltersButtonClick}
        size="xsmall"
        variant="secondary"
        height="65%"
        alignSelf="flex-end"
        flex={1}
        width="8%"
        loading={isLoadingFilter}
      />
    </Box>
  );
};
