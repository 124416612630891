/* storybook-check-ignore */
import { PropsWithChildren } from 'react';

import { Box } from '@opendoor/bricks-next';
import { ThemedLite } from '@opendoor/bricks/theme/ODThemeLite';
import Head from 'next/head';

import Header from 'components/landing-pages-v2/shared/header/Header';
import GlobalStyles from 'components/shared/GlobalStyles';

const setHeight = `
  html,
  body,
  div#__next {
    height: 100%;
  }
`;

const mainNavLinks = [
  {
    name: 'How it works',
    href: '/how-it-works-sell',
  },
  {
    name: 'Home insights',
    href: '/home-insights',
  },
  {
    name: 'Browse homes',
    href: '/homes',
  },
];

const menuPanelNavLinks = [
  {
    name: 'Work with a builder',
    href: '/builder',
  },
  {
    name: 'Opendoor for Agents',
    href: '/agents',
  },
];

const MarketplaceMapLayout = ({ children }: PropsWithChildren) => {
  return (
    <ThemedLite
      baseTheme="novo"
      team="marketplace"
      launchDateString={new Date().toISOString()}
      overrides={{}}
    >
      <Head>
        <link rel="preconnect" href="https://images.opendoor.com" />
        <link rel="dns-prefetch" href="https://images.opendoor.com" />
        <link rel="preconnect" href="https://cdn-cosmos.opendoor.com" />
        <link rel="dns-prefetch" href="https://cdn-cosmos.opendoor.com" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta httpEquiv="Accept-CH" content="DPR, Width, Viewport-Width" />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="https://www.opendoor.com/apple-touch-icon-144.png"
        />
        <style>{setHeight}</style>
      </Head>
      <GlobalStyles fontWeights={['regular', 'semibold', 'bold', 'medium']} />
      <Box position="absolute" display="block" width="100%" zIndex={1000}>
        <Header slug="homes" mainNavLinks={mainNavLinks} menuPanelNavLinks={menuPanelNavLinks} />
      </Box>
      <Box
        tag="main"
        tabIndex={-1}
        flexDirection="column"
        overflow="hidden"
        backgroundColor="$backgroundPrimary"
        height="100%"
        pt={70}
      >
        {children}
      </Box>
    </ThemedLite>
  );
};

export default MarketplaceMapLayout;
