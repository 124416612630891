/* storybook-check-ignore */
import { Box, Dropdown, Text } from '@opendoor/bricks-next';

import { ErrorKey, RangeOption } from 'components/marketplace/search/types';

import { filterIntOptions } from './utils';

const SquareFootageOptions: RangeOption[] = [
  { label: '1,000 sq ft', value: '1000' },
  { label: '1,500 sq ft', value: '1500' },
  { label: '2,000 sq ft', value: '2000' },
  { label: '2,500 sq ft', value: '2500' },
  { label: '3,000 sq ft', value: '3000' },
  { label: '3,500 sq ft', value: '3500' },
  { label: '4,000 sq ft', value: '4000' },
  { label: '5,000 sq ft', value: '5000' },
];

export interface SquareFootageFilterProps {
  minSquareFootage?: string | null;
  maxSquareFootage?: string | null;
  handleMinSquareFootageChange: (value: string) => void;
  handleMaxSquareFootageChange: (value: string) => void;
  handleError: (key: ErrorKey, val: string) => void; // used to notify parent component of error state
  errors?: Record<ErrorKey, string> | null;
}

export const SquareFootageFilter = ({
  minSquareFootage,
  maxSquareFootage,
  handleMinSquareFootageChange,
  handleMaxSquareFootageChange,
  handleError,
  errors,
}: SquareFootageFilterProps) => {
  const onMinSquareFootageChange = (value: string) => {
    if (maxSquareFootage && parseInt(value, 10) >= parseInt(maxSquareFootage, 10)) {
      handleError('minSquareFootage', 'Min square footage must be less than max square footage');
      return;
    }
    handleError('minSquareFootage', '');
    handleMinSquareFootageChange(value);
  };

  const onMaxSquareFootageChange = (value: string) => {
    if (minSquareFootage && parseInt(value, 10) < parseInt(minSquareFootage, 10)) {
      handleError('maxSquareFootage', 'Max square footage must be greater than min square footage');

      return;
    }
    handleError('maxSquareFootage', '');
    handleMaxSquareFootageChange(value);
  };

  const minOptions = filterIntOptions(SquareFootageOptions, 'lt', maxSquareFootage ?? undefined);
  const maxOptions = filterIntOptions(SquareFootageOptions, 'gt', minSquareFootage ?? undefined);

  return (
    <Box flexDirection="column" width="100%">
      <Text tag="p" typography="$labelXlarge">
        Square feet
      </Text>
      <Box flexDirection="row" width="100%" justifyContent="space-between">
        <Box width="48%">
          <Dropdown
            title=" "
            placeholderOption={{
              label: 'No min',
              value: '0',
            }}
            data-testid="min-square-footage-dropdown"
            options={minOptions}
            onValueChange={onMinSquareFootageChange}
            size="small"
            prefilledValue={minSquareFootage || undefined}
            errorText={errors?.['minSquareFootage']}
          />
        </Box>
        <Box width="48%">
          <Dropdown
            title=" "
            placeholderOption={{
              label: 'No max',
              value: '10000',
            }}
            data-testid="max-square-footage-dropdown"
            options={maxOptions}
            onValueChange={onMaxSquareFootageChange}
            size="small"
            prefilledValue={maxSquareFootage || undefined}
            errorText={errors?.['maxSquareFootage']}
          />
        </Box>
      </Box>
    </Box>
  );
};
