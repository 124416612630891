import { getRailsEnv } from '@opendoor/shared-fe/build/railsEnv';

const railsEnv = getRailsEnv();

const useStaging = process.env.REACT_APP_ATHENA_URL?.includes('staging');

const env = useStaging ? 'staging' : railsEnv;
export const RAILS_ENV = env;
export const offerPollingTimeoutThreshold = env === 'production' ? 90_600 : 120_000;
export const backendRefreshBlockDurationMS = 120_000;

export const HOMEPAGE_URL = {
  production: 'https://www.opendoor.com',
  staging: 'https://demo.simplersell.com',
  qa: 'https://demo.simplersell.com',
  development: 'http://localhost:3011/homepage-2019',
  test: 'https://www.opendoor.com',
}[env];

export const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1Ijoib3BlbmRvb3IiLCJhIjoiWXJ2bTJiRSJ9.lsnjT9yTNyyVzKRIzqxnqg';
export const MAPBOX_MAP_STYLE = 'mapbox://styles/opendoor/cktkqasmc1gbe17pfo2cm7ybb';

const getIsIOS = () => {
  const userAgent = typeof navigator !== 'undefined' && (navigator.userAgent || navigator.vendor);
  return userAgent && /iPad|iPhone|iPod/.test(userAgent);
};

const platform = getIsIOS() ? 'ios' : 'android';

export const OD_APP_URL = {
  ios: 'https://itunes.apple.com/us/app/opendoor-homes-all-day-open/id1143708068?mt=8',
  android: 'https://play.google.com/store/apps/details?id=com.opendoor.buyerapp&hl=en',
}[platform];
