import { ParsedUrlQuery } from 'querystring';

import { GetMarketplacePropertySearchQueryVariables, PropertyType } from '__generated__/athena';

import { getAthenaClient } from 'components/api';

import { GeoPoint } from '../map/types';

interface PropertyListAndSearchProps {
  bedrooms?: number | null;
  bathrooms?: number | null;
  canSelfTour?: boolean | null;
  minListPrice?: number | null;
  maxListPrice?: number | null;
  minSquareFootage?: number | null;
  maxSquareFootage?: number | null;
  hasSingleStory?: boolean | null;
  hasPool?: boolean | null;
  singleFamily?: boolean | null;
  condo?: boolean | null;
  townhome?: boolean | null;
  zoneIds?: string[] | null;
  minYearBuilt?: number | null;
  maxYearBuilt?: number | null;
  vertices?: GeoPoint[] | null;
  market?: string | null;
}

export const useSearchQueries = () => {
  const propertyListAndSearch = async (input: PropertyListAndSearchProps, page: number) => {
    const queryParams = buildQueryParams(input);
    window.history.replaceState({}, '', `${window.location.pathname}?${queryParams}`);

    const propertyTypes: PropertyType[] = [];
    if (input.singleFamily) {
      propertyTypes.push('HOUSE' as PropertyType);
    }

    if (input.condo) {
      propertyTypes.push('CONDO' as PropertyType);
    }

    if (input.townhome) {
      propertyTypes.push('TOWNHOUSE' as PropertyType);
    }

    let payload: GetMarketplacePropertySearchQueryVariables = {
      bedrooms: input?.bedrooms,
      bathrooms: input?.bathrooms,
      canSelfTour: input?.canSelfTour,
      minListPrice: input?.minListPrice,
      maxListPrice: input?.maxListPrice,
      singleStoryOnly: input?.hasSingleStory,
      hasPool: input?.hasPool,
      minYearBuilt: input?.minYearBuilt,
      maxYearBuilt: input?.maxYearBuilt,
      minSquareFootage: input?.minSquareFootage,
      maxSquareFootage: input?.maxSquareFootage,
      propertyTypes,
    };

    if ((input.vertices?.length ?? 0) > 0) {
      payload = { ...payload, vertices: input.vertices };
    } else if (input.zoneIds) {
      payload = { ...payload, zoneIds: input.zoneIds };
    } else if (input.market) {
      payload = { ...payload, market: input.market };
    }

    const searchResult = await getAthenaClient().GetMarketplacePropertySearch({ ...payload });
    const listResult = await getAthenaClient().GetMarketplacePropertyList({ page, ...payload });

    return { searchResult, listResult };
  };
  return { propertyListAndSearch };
};

export const buildQueryParams = (input: PropertyListAndSearchProps): Record<string, any> => {
  const urlParams = new URLSearchParams(window.location.search);
  for (const [key, value] of Object.entries(input)) {
    if (key === 'zoneIds') {
      urlParams.set('zoneIds', value?.join(','));
    } else if (key === 'vertices') {
      value.forEach((vertex: GeoPoint, indx: number) => {
        urlParams.set(`vertex_${indx}`, encodeURI(JSON.stringify(vertex)));
      });
    } else if (key === 'market') {
      urlParams.set('market', value);
    } else if (value) {
      urlParams.set(key, value.toString());
    } else {
      urlParams.delete(key);
    }
  }
  return urlParams;
};

export const buildVerticesQueryParams = (vertices: GeoPoint[]): Record<string, any> => {
  const urlParams = new URLSearchParams(window.location.search);
  vertices.forEach((vertex, indx) => {
    urlParams.set(`vertex_${indx}`, encodeURI(JSON.stringify(vertex)));
  });
  return urlParams;
};

export const buildAddressPreviewParams = (addressToken: string): Record<string, any> => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.set('preview_aid', addressToken);
  return urlParams;
};

export const removeAddressPreviewParams = (): Record<string, any> => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.delete('preview_aid');
  return urlParams;
};

export const parseQueryParams = (urlParams: ParsedUrlQuery): Record<string, any> => {
  const queryParams: Record<string, any> = {};
  queryParams.vertices = [];

  Object.entries(urlParams)
    .sort((a, b) => a[0].localeCompare(b[0]))
    .forEach(([key, value]) => {
      if (key === 'zoneIds' && typeof value === 'string') {
        queryParams.zoneIds = value.split(',');
      } else if (key.startsWith('vertex_') && typeof value === 'string') {
        queryParams.vertices.push(JSON.parse(decodeURI(value)));
      } else {
        if (!isNaN(Number(value))) {
          queryParams[key] = Number(value as string);
        } else if (value === 'true' || value === 'false') {
          queryParams[key] = value === 'true';
        } else {
          queryParams[key] = value;
        }
      }
    });
  return queryParams;
};
