/* storybook-check-ignore */
import { Box, Dropdown, Text } from '@opendoor/bricks-next';

import { ErrorKey, RangeOption } from 'components/marketplace/search/types';

import { filterIntOptions } from './utils';

const LotSizeOptions: RangeOption[] = [
  { label: '2000 sq ft', value: '2000' },
  { label: '4000 sq ft', value: '4000' },
  { label: '6000 sq ft', value: '6000' },
  { label: '8000 sq ft', value: '8000' },
  { label: '0.25 acres', value: '10890' },
  { label: '0.5 acres', value: '21780' },
  { label: '1 acre', value: '43560' },
  { label: '2 acres+', value: '87120' },
];

export interface LotSizeFilterProps {
  minLotSize?: string | null;
  maxLotSize?: string | null;
  handleMinLotSizeChange: (value: string) => void;
  handleMaxLotSizeChange: (value: string) => void;
  handleError: (key: ErrorKey, val: string) => void; // used to notify parent component of error state
  errors?: Record<ErrorKey, string> | null;
}

export const LotSizeFilter = ({
  minLotSize,
  maxLotSize,
  handleMinLotSizeChange,
  handleMaxLotSizeChange,
  handleError,
  errors,
}: LotSizeFilterProps) => {
  const onMinLotSizeChange = (value: string) => {
    if (maxLotSize && parseInt(value, 10) > parseInt(maxLotSize, 10)) {
      handleError('minLotSize', 'Min lot size must be less than max lot size');
      return;
    }
    handleError('minLotSize', '');
    handleMinLotSizeChange(value);
  };

  const onMaxLotSizeChange = (value: string) => {
    if (minLotSize && parseInt(value, 10) < parseInt(minLotSize, 10)) {
      handleError('maxLotSize', 'Max lot size must be greater than min lot size');

      return;
    }
    handleError('maxLotSize', '');
    handleMaxLotSizeChange(value);
  };

  const minOptions = filterIntOptions(LotSizeOptions, 'lt', maxLotSize ?? undefined);
  const maxOptions = filterIntOptions(LotSizeOptions, 'gt', minLotSize ?? undefined);

  return (
    <Box flexDirection="column" width="100%">
      <Text tag="p" typography="$labelXlarge">
        Lot size
      </Text>
      <Box flexDirection="row" width="100%" justifyContent="space-between">
        <Box width="48%">
          <Dropdown
            title=" "
            placeholderOption={{
              label: 'No min',
              value: '0',
            }}
            data-testid="min-lot-size-dropdown"
            options={minOptions}
            onValueChange={onMinLotSizeChange}
            size="small"
            prefilledValue={minLotSize || undefined}
            errorText={errors?.['minLotSize']}
          />
        </Box>
        <Box width="48%">
          <Dropdown
            title=" "
            placeholderOption={{
              label: 'No max',
              value: '500000000',
            }}
            data-testid="max-lot-size-dropdown"
            options={maxOptions}
            onValueChange={onMaxLotSizeChange}
            size="small"
            prefilledValue={maxLotSize || undefined}
            errorText={errors?.['maxLotSize']}
          />
        </Box>
      </Box>
    </Box>
  );
};
