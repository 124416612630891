/* storybook-check-ignore */
import { Box, SimpleCheckbox, Text } from '@opendoor/bricks-next';

export interface HomeFeaturesFilterProps {
  noHoas?: boolean | null;
  fhaEligible?: boolean | null;
  mustHavePool?: boolean | null;
  singleStory?: boolean | null;
  handleNoHoasChange: (_: string, checked: string | boolean) => void;
  handleSingleStoryChange?: (_: string, checked: string | boolean) => void;
  handleFhaEligibleChange?: (_: string, checked: string | boolean) => void;
  handlePoolChange: (_: string, checked: string | boolean) => void;
}

export const HomeFeaturesFilter = ({
  noHoas,
  fhaEligible,
  mustHavePool,
  singleStory,
  handleNoHoasChange,
  handleFhaEligibleChange,
  handleSingleStoryChange,
  handlePoolChange,
}: HomeFeaturesFilterProps) => {
  return (
    <Box flexDirection="column" width="100%" justifyContent="space-between">
      <Text
        tag="p"
        typography="$labelXlarge"
        color="$contentPrimary"
        alignSelf="flex-start"
        mb={20}
      >
        Home features
      </Text>
      <Box flexDirection="row" justifyContent="space-between" alignItems="center" my={10}>
        <Text tag="p" typography="$bodyMedium" color="$contentPrimary">
          No HOA Only
        </Text>
        <SimpleCheckbox
          ariaLabel="hoa-checkbox"
          analyticsName="hoa-checkbox"
          id="hoa-checkbox"
          size="medium"
          name="hoa"
          value="true"
          defaultChecked={!!noHoas}
          onPress={handleNoHoasChange}
        />
      </Box>
      {handleSingleStoryChange && (
        <Box flexDirection="row" justifyContent="space-between" alignItems="center" my={10}>
          <Text tag="p" typography="$bodyMedium" color="$contentPrimary">
            Single-story only
          </Text>
          <SimpleCheckbox
            ariaLabel="single-story-checkbox"
            analyticsName="single-story-checkbox"
            id="single-story-checkbox"
            size="medium"
            name="singleStory"
            value="true"
            defaultChecked={!!singleStory}
            onPress={handleSingleStoryChange}
          />
        </Box>
      )}
      {handleFhaEligibleChange && (
        <Box flexDirection="row" justifyContent="space-between" alignItems="center" my={10}>
          <Text tag="p" typography="$bodyMedium" color="$contentPrimary">
            FHA Eligible Only
          </Text>
          <SimpleCheckbox
            ariaLabel="fha-checkbox"
            analyticsName="fha-checkbox"
            id="fha-checkbox"
            size="medium"
            name="fha"
            value="true"
            defaultChecked={!!fhaEligible}
            onPress={handleFhaEligibleChange}
          />
        </Box>
      )}
      <Box flexDirection="row" justifyContent="space-between" alignItems="center" mt={10}>
        <Text tag="p" typography="$bodyMedium" color="$contentPrimary">
          With Pool Only (includes community pools)
        </Text>
        <SimpleCheckbox
          ariaLabel="pool-checkbox"
          analyticsName="pool-checkbox"
          id="pool-checkbox"
          size="medium"
          name="pool"
          value="true"
          defaultChecked={!!mustHavePool}
          onPress={handlePoolChange}
        />
      </Box>
    </Box>
  );
};
