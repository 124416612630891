/* storybook-check-ignore */
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import {
  Box,
  PhotoCarousel as BricksPhotoCarousel,
  CloseButton,
  PhotoCarouselAspectRatios,
  ShareButton,
} from '@opendoor/bricks-next';

import { MOBILE_CAROUSEL_HEIGHT_MD, MOBILE_CAROUSEL_HEIGHT_SM } from './types';

interface PhotoCarouselProps {
  photos: string[] | null | undefined;
  onClose: () => void;
  onOpenShareModal: () => void;
  isRounded?: boolean;
  height?: number;
  onPhotoClick?: () => void;
}

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;
export const PhotoCarousel = ({
  photos,
  onClose,
  isRounded = false,
  onOpenShareModal,
  onPhotoClick,
  height,
}: PhotoCarouselProps) => {
  const [aspectRatio, setAspectRatio] = useState<number>(PhotoCarouselAspectRatios.DEFAULT);
  const [shouldHideNavigation, setShouldHideNavigation] = useState(false);
  const carouselRef = useRef<HTMLDivElement>(null);

  const mappedPhotos = useMemo(
    () =>
      photos?.map(
        (photo) => {
          return {
            url: photo + '?service=cosmos&preset=3%3A2-md&presetScale=2%2F3&dpr=1',
            alt: '',
          };
        },
        [photos],
      ) || [],
    [photos],
  );

  useEffect(() => {
    const carousel = carouselRef.current;
    let startY = 0;
    let startX = 0;

    const handleTouchStart = (e: TouchEvent) => {
      startY = e.touches[0].clientY; // Set start Y to the initial touch Y position
      startX = e.touches[0].clientX; // Set start X to the initial touch X position
    };

    const handleTouchMove = (e: TouchEvent) => {
      // Calculate the difference between current touch position and start position
      const moveY = e.touches[0].clientY - startY;
      const moveX = e.touches[0].clientX - startX;

      // We're doing this because we want to allow vertical scrolling
      // if the user starts scrolling vertically first
      // But if they start scrolling horizontally first, we want to prevent vertical scrolling
      if (Math.abs(moveX) > Math.abs(moveY)) {
        e.preventDefault(); // Prevent Vertical scrolling
      } else {
        e.stopPropagation(); // Prevent Horizontal scrolling
      }
    };

    if (carousel) {
      carousel.addEventListener('touchstart', handleTouchStart, { passive: true });
      carousel.addEventListener('touchmove', handleTouchMove, { passive: false });
    }

    return () => {
      if (carousel) {
        // Clean up event listeners
        carousel.removeEventListener('touchstart', handleTouchStart);
        carousel.removeEventListener('touchmove', handleTouchMove);
      }
    };
  }, [carouselRef]);

  useEffect(() => {
    const carousel = carouselRef.current;
    if (!carousel) return;

    const handleClick = (e: any) => {
      const tag = e.target.tagName;
      if (tag === 'IMG' && onPhotoClick) {
        onPhotoClick();
      }
    };

    carousel.addEventListener('click', handleClick);

    return () => {
      carousel.removeEventListener('click', handleClick);
    };
  }, [carouselRef]);

  const CarouselWidths = {
    smallest: 360,
    small: 375,
    smallMedium: 600,
    medium: 768,
    mediumLarge: 992,
  };

  const WIDTH_TO_ASPECT_RATIO = {
    [CarouselWidths.smallest]: PhotoCarouselAspectRatios.DEFAULT,
    [CarouselWidths.small]: PhotoCarouselAspectRatios.SQUARE,
    [CarouselWidths.smallMedium]: PhotoCarouselAspectRatios.SQUARE,
    [CarouselWidths.medium]: PhotoCarouselAspectRatios.SQUARE,
    [CarouselWidths.mediumLarge]: PhotoCarouselAspectRatios.SQUARE,
  };

  useIsomorphicLayoutEffect(() => {
    // Function to calculate and set the aspect ratio
    const updateAspectRatio = () => {
      const containerWidth = window.innerWidth;
      let newAspectRatio = PhotoCarouselAspectRatios.DEFAULT; // Default aspect ratio
      let shouldHideNav = false;

      if (containerWidth < CarouselWidths.smallest) {
        newAspectRatio = PhotoCarouselAspectRatios.DEFAULT;
        shouldHideNav = true;
      } else if (containerWidth <= CarouselWidths.small) {
        newAspectRatio = WIDTH_TO_ASPECT_RATIO[CarouselWidths.small];
        shouldHideNav = true;
      } else if (containerWidth < CarouselWidths.smallMedium) {
        newAspectRatio = WIDTH_TO_ASPECT_RATIO[CarouselWidths.smallMedium];
        shouldHideNav = true;
      } else if (containerWidth <= CarouselWidths.medium) {
        newAspectRatio = WIDTH_TO_ASPECT_RATIO[CarouselWidths.medium];
        shouldHideNav = false;
      } else if (containerWidth <= CarouselWidths.mediumLarge) {
        newAspectRatio = WIDTH_TO_ASPECT_RATIO[CarouselWidths.mediumLarge];
        shouldHideNav = false;
      }

      setShouldHideNavigation(shouldHideNav);
      setAspectRatio(newAspectRatio);
    };

    // Call updateAspectRatio initially to set the aspect ratio based on the current window size
    updateAspectRatio();

    // Add event listener for window resize
    window.addEventListener('resize', updateAspectRatio);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener('resize', updateAspectRatio);
  }, []);

  return (
    <Box
      position="relative"
      aspectRatio={aspectRatio}
      justifyContent="center"
      overflow="hidden"
      width="100%"
      ref={carouselRef}
      $smallerThanMD={{ height: MOBILE_CAROUSEL_HEIGHT_SM }}
      $smallerThanLG={{ height: MOBILE_CAROUSEL_HEIGHT_MD }}
      $largerThanMD={{ mt: 0 }}
    >
      <CloseButton
        id="close-button"
        size="large"
        onPress={onClose}
        analyticsName="marketplace-carousel-back-button"
        top="$8x"
        left={24}
        position="absolute"
        zIndex={1}
        data-testid="map-pdp-close-button"
      />
      <ShareButton
        id="share-button"
        size="large"
        onPress={onOpenShareModal}
        analyticsName="marketplace-carousel-share-button"
        top="$8x"
        right={24}
        position="absolute"
        zIndex={1}
      />
      <BricksPhotoCarousel
        photos={mappedPhotos}
        hideNavigation={shouldHideNavigation}
        aspectRatio={aspectRatio}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        height={height || '100%'}
        isRounded={isRounded || false}
      ></BricksPhotoCarousel>
    </Box>
  );
};
