/* storybook-check-ignore */
import { Box, Dropdown, Text } from '@opendoor/bricks-next';

const RoomSizeOptions = [
  { label: '1+', value: '1' },
  { label: '2+', value: '2' },
  { label: '3+', value: '3' },
  { label: '4+', value: '4' },
];

export interface BedroomsBathroomsFilterProps {
  minBaths?: string | null;
  minBeds?: string | null;
  handleMinBathsChange: (value: string) => void;
  handleMinBedsChange: (value: string) => void;
}

export const BedroomsBathroomsFilter = ({
  minBaths,
  minBeds,
  handleMinBathsChange,
  handleMinBedsChange,
}: BedroomsBathroomsFilterProps) => {
  return (
    <Box flexDirection="column" width="100%">
      <Text tag="p" typography="$labelXlarge">
        Beds/Baths
      </Text>
      <Box flexDirection="row" width="100%" justifyContent="space-between" alignItems="center">
        <Text tag="p" typography="$bodyMedium" mt={24}>
          Beds
        </Text>
        <Box width="30%" $smallerThanMD={{ width: '40%' }}>
          <Dropdown
            title=" "
            placeholderOption={{
              label: 'Min beds',
              value: '0',
            }}
            options={RoomSizeOptions.slice()}
            onValueChange={handleMinBedsChange}
            size="small"
            prefilledValue={minBeds || undefined}
          />
        </Box>
      </Box>
      <Box flexDirection="row" width="100%" justifyContent="space-between" alignItems="center">
        <Text tag="p" typography="$bodyMedium" mt={24}>
          Baths
        </Text>
        <Box width="30%" $smallerThanMD={{ width: '40%' }}>
          <Dropdown
            title=" "
            placeholderOption={{
              label: 'Min baths',
              value: '0',
            }}
            options={RoomSizeOptions.slice()}
            onValueChange={handleMinBathsChange}
            size="small"
            prefilledValue={minBaths || undefined}
          />
        </Box>
      </Box>
    </Box>
  );
};
