/* storybook-check-ignore */
import { useCallback, useEffect, useState } from 'react';
import { Marker, Popup } from 'react-map-gl';

import { css, Global } from '@emotion/react';
import { Box, Image as BricksImage, Icons, PressableBox, Text } from '@opendoor/bricks-next';

import { MapMarkerDetailsFragment } from '__generated__/athena';

import { getAthenaClient } from 'components/api';

import { convertDwellingType } from '../property/helpers';
import { MapMarkerProps } from './types';

function formatNumber(number: number) {
  return number.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: number >= 1_000_000 ? 2 : 0,
    notation: 'compact',
    compactDisplay: 'short',
  });
}

const PRIMARY_PHOTO_WIDTH = 350;
const PRIMARY_PHOTO_HEIGHT = 150;
const SECONDARY_PHOTO_WIDTH = 172;
const SECONDARY_PHOTO_HEIGHT = 100;

const THUMBNAIL_PARAMS = new URLSearchParams({
  service: 'cosmos',
  preset: '3:2-xs',
}).toString();

export const MapMarker = ({
  property,
  onClick,
  moveToTop,
  detailCache,
  index: markerIndex,
  setFocused: focusTopMarker,
}: MapMarkerProps) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const isActive = isHovering || isFocused;
  const [propertyDetails, setPropertyDetails] = useState<MapMarkerDetailsFragment | null>();
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [pressableRef, setPressableRef] = useState<HTMLElement | null>(null);

  const fetchMemoizedPropertyDetails = useCallback(async () => {
    const fetchPropertyDetails = async (): Promise<MapMarkerDetailsFragment | null> => {
      const result = await getAthenaClient().GetMarketplacePropertyDetailsForMapMarkers({
        addressToken: property.addressToken,
      });

      return result?.marketplace?.property || null;
    };

    const token = property.addressToken;
    if (!detailCache.current.has(token)) {
      detailCache.current.set(token, fetchPropertyDetails());
    }

    return detailCache.current.get(token);
  }, [property.addressToken]);

  useEffect(() => {
    if (focusTopMarker && pressableRef) {
      pressableRef.focus();
    }
  }, [pressableRef]);

  const preparePopup = async () => {
    const details = await fetchMemoizedPropertyDetails();

    if (imageUrls.length === 0) {
      setImageUrls(details?.photos?.slice(0, 3).map((url) => `${url}?${THUMBNAIL_PARAMS}`) ?? []);
    }

    setPropertyDetails(details);
  };

  const handleOnHover = () => {
    setIsHovering(true);
    moveToTop?.(isFocused);
    preparePopup();
  };

  const handleOnFocus = () => {
    moveToTop?.(true);
    setIsFocused(true);
    preparePopup();
  };

  useEffect(() => {
    imageUrls.map((url) => {
      const image = new Image();
      image.onload = () => {
        setImagesLoaded((value) => value + 1);
      };
      image.src = url;
    });
  }, [imageUrls]);

  const handleOnMouseOut = () => {
    setIsHovering(false);
  };

  const handleOnBlur = () => {
    setIsFocused(false);
  };

  const handleOnClick = () => {
    setIsHovering(false);
    setIsFocused(false);
    onClick();
  };

  return (
    <>
      <Global
        styles={css`
          .mapboxgl-popup-tip {
            display: none;
          }
          .mapboxgl-popup-content {
            width: 350px;
            height: 360px;
            padding: 0 0 0 0px;
            border-radius: 15px !important;
            right: 50px;
          }
          .mapboxgl.popup {
            border-radius: 15px !important;
          }
        `}
      />
      <Marker
        key={`map-marker-${property.addressToken}`}
        latitude={property.location?.lat}
        longitude={property.location?.lon}
      >
        <PressableBox
          aria-label="Property marker"
          analyticsName="property-marker-click"
          backgroundColor={isActive ? '$backgroundInversePrimary' : '$backgroundPrimary'}
          borderRadius="$32x"
          height={28}
          width={property?.isLiked ? 76 : 60}
          zIndex={1}
          tabIndex={markerIndex + 10}
          justifyContent="center"
          alignItems="center"
          onMouseEnter={handleOnHover}
          onMouseLeave={handleOnMouseOut}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          onPress={handleOnClick}
          ref={(ref) => setPressableRef(ref as HTMLElement)}
          data-testid={`map-marker-${property.addressToken}`}
        >
          <Box flexDirection="row" alignItems="flex-start">
            {property?.isLiked && (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <Icons.FavoritedFilled size={14} color="$contentStateErrorDefault" pr={4} />
            )}
            {property.listPrice && (
              <Text
                tag="span"
                typography="$labelSmall"
                color={isActive ? '$contentInversePrimary' : '$contentPrimary'}
              >
                {`${formatNumber(property.listPrice)}`}
              </Text>
            )}
          </Box>
        </PressableBox>
        {isActive && imagesLoaded >= 3 && (
          <Popup
            longitude={property.location?.lon}
            latitude={property.location?.lat}
            anchor="bottom"
            offset={20}
            closeButton={false}
          >
            <BricksImage
              alt="map-marker-primary-property-image"
              source={{
                height: PRIMARY_PHOTO_HEIGHT,
                uri: imageUrls[0],
                width: PRIMARY_PHOTO_WIDTH,
              }}
              borderTopRightRadius="$8x"
              borderTopLeftRadius="$8x"
            />
            <Box flexDirection="row" justifyContent="space-between" mt={6}>
              <BricksImage
                alt="map-marker-secondary-property-image"
                source={{
                  height: SECONDARY_PHOTO_HEIGHT,
                  uri: imageUrls[1],
                  width: SECONDARY_PHOTO_WIDTH,
                }}
              />
              <BricksImage
                alt="map-marker-tertiary-property-image"
                source={{
                  height: SECONDARY_PHOTO_HEIGHT,
                  uri: imageUrls[2],
                  width: SECONDARY_PHOTO_WIDTH,
                }}
              />
            </Box>
            <Box
              flexDirection="row"
              mt={24}
              alignItems="baseline"
              mx={20}
              justifyContent="space-between"
            >
              {property.listPrice && (
                <Text typography="$labelXlarge" tag="p">
                  {`$${property.listPrice.toLocaleString()}`}
                </Text>
              )}
              <Text typography="$bodySmall" tag="span" color="$contentTertiary" ml={-24}>
                {`$${propertyDetails?.estimatedMonthlyPayment?.toLocaleString()}`}/month est.
              </Text>
              {property?.isLiked && (
                <Icons.FavoritedFilled
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  size={14}
                  color="$contentStateErrorDefault"
                  justifySelf="flex-end"
                  alignSelf="baseline"
                />
              )}
            </Box>
            <Box flexDirection="row" alignItems="baseline" width="100%" mt={12} mx={20}>
              <Text tag="span" typography="$bodySmall" color="$contentTertiary">
                {propertyDetails?.bedrooms && `${propertyDetails?.bedrooms} bds`}
              </Text>
              {propertyDetails?.bathrooms && (
                <>
                  <Text tag="span" typography="$bodySmall" color="$contentStateInactiveSecondary">
                    {' • '}
                  </Text>
                  <Text tag="span" typography="$bodySmall" color="$contentTertiary">
                    {`${propertyDetails?.bathrooms} ba`}
                  </Text>
                </>
              )}
              {propertyDetails?.sqFtTotalLiving && (
                <>
                  <Text tag="span" typography="$bodySmall" color="$contentStateInactiveSecondary">
                    {' • '}
                  </Text>
                  <Text tag="span" typography="$bodySmall" color="$contentTertiary">
                    {`${propertyDetails?.sqFtTotalLiving.toLocaleString()} sqft`}
                  </Text>
                </>
              )}
              {propertyDetails?.dwellingType && (
                <>
                  <Text tag="span" typography="$bodySmall" color="$contentStateInactiveSecondary">
                    {' • '}
                  </Text>
                  <Text tag="span" typography="$bodySmall" color="$contentTertiary">
                    {convertDwellingType(propertyDetails?.dwellingType)}
                  </Text>
                </>
              )}
            </Box>
          </Popup>
        )}
      </Marker>
    </>
  );
};
