/* storybook-check-ignore */
import { useState } from 'react';

import { Box } from '@opendoor/bricks-next';

import { removeAddressPreviewParams } from 'components/marketplace/helpers/useSearchQueries';
import { PhotoCarousel as MapPhotoCarousel } from 'components/marketplace/map/PhotoCarousel';
import {
  FooterCta,
  Lightbox,
  PropertyDetailsType,
  ShareModal,
} from 'components/marketplace/property';
import { PropertyCTAs } from 'components/marketplace/property/PropertySection/PropertyCTAs';
import { PropertyDetails } from 'components/marketplace/property/PropertySection/PropertyDetails';
import { PropertyStatus } from 'components/marketplace/property/PropertySection/PropertyStatus';
import { PropertySummary } from 'components/marketplace/property/PropertySection/PropertySummary';

interface MiniPropertyDetailPageProps {
  property: PropertyDetailsType;
  onClose: () => void;
}

export const MiniPropertyDetailPage = ({ property, onClose }: MiniPropertyDetailPageProps) => {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState<number>(0);
  if (!property) {
    return <Box />;
  }

  const handleOnClose = () => {
    const urlParams = removeAddressPreviewParams();
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
    onClose();
  };

  const handleToggleShareModal = () => {
    setIsShareModalOpen((prev) => !prev);
  };

  const toggleLightbox = () => {
    setIsLightboxOpen((prev) => !prev);
    setSelectedPhotoIndex(0);
  };

  return (
    <Box height="100%" key={`mini-pdp-${property?.addressToken}`}>
      <MapPhotoCarousel
        photos={property?.photos}
        onClose={handleOnClose}
        onOpenShareModal={handleToggleShareModal}
        onPhotoClick={toggleLightbox}
        key={`mini-pdp-carousel-${property?.addressToken}`}
      />
      <Box position="relative">
        <Box px={48} pt={24}>
          <PropertyStatus property={property} />
          <PropertySummary property={property} />
          <PropertyCTAs property={property} />
          <PropertyDetails property={property} />
        </Box>
        {property?.displayBadge === 'ON_THE_MARKET' && (
          <FooterCta kind="selfTour" property={property} />
        )}
        {(property?.displayBadge === 'TRADITIONAL' || property?.displayBadge === 'UNKNOWN') &&
          !property.canSelfTour && <FooterCta kind="tourWithAgent" property={property} />}
        <Lightbox
          handleClose={toggleLightbox}
          isOpen={isLightboxOpen}
          property={property}
          selectedPhotoIndex={selectedPhotoIndex}
        />
      </Box>
      <ShareModal
        key={`share-modal-${property?.addressToken}`}
        property={property}
        isOpen={isShareModalOpen}
        onClose={handleToggleShareModal}
      />
    </Box>
  );
};
