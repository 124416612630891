/* storybook-check-ignore */
import { Divider } from '@opendoor/bricks/core';

import { BedroomsBathroomsFilter, BedroomsBathroomsFilterProps } from './BedroomsBathroomsFilter';
import { HomeFeaturesFilter, HomeFeaturesFilterProps } from './HomeFeaturesFilter';
import { HomeTypeFilter, HomeTypeFilterProps } from './HomeTypeFilter';
import { LotSizeFilter, LotSizeFilterProps } from './LotSizeFilter';
import { PriceFilter, PriceFilterProps } from './PriceFilter';
import { SquareFootageFilter, SquareFootageFilterProps } from './SquareFootageFilter';
import { YearBuiltFilter, YearBuiltFilterProps } from './YearBuiltFilter';

export type SearchFiltersProps = PriceFilterProps &
  BedroomsBathroomsFilterProps &
  SquareFootageFilterProps &
  HomeTypeFilterProps &
  HomeFeaturesFilterProps &
  YearBuiltFilterProps &
  LotSizeFilterProps & {
    canSelfTour?: boolean | null;
    onCanSelfTourChange?: (value: string) => void;
  };

export const SearchFilters = (props: SearchFiltersProps) => {
  return (
    <>
      <PriceFilter
        minPrice={props?.minPrice}
        maxPrice={props?.maxPrice}
        handleMinPriceChange={props.handleMinPriceChange}
        handleMaxPriceChange={props.handleMaxPriceChange}
        handleError={props.handleError}
        errors={props.errors}
      />
      <Divider my={30} />
      <BedroomsBathroomsFilter
        minBaths={props?.minBaths}
        minBeds={props?.minBeds}
        handleMinBathsChange={props.handleMinBathsChange}
        handleMinBedsChange={props.handleMinBedsChange}
      />
      <Divider my={30} />
      <HomeTypeFilter
        singleFamily={props?.singleFamily}
        condo={props?.condo}
        townhome={props?.townhome}
        handleSingleFamilyChange={props.handleSingleFamilyChange}
        handleCondoChange={props.handleCondoChange}
        handleTownhomeChange={props.handleTownhomeChange}
      />
      <Divider my={30} />
      <HomeFeaturesFilter
        noHoas={props?.noHoas}
        singleStory={props?.singleStory}
        fhaEligible={props?.fhaEligible}
        mustHavePool={props?.mustHavePool}
        handleNoHoasChange={props.handleNoHoasChange}
        handleSingleStoryChange={props.handleSingleStoryChange}
        handleFhaEligibleChange={props.handleFhaEligibleChange}
        handlePoolChange={props.handlePoolChange}
      />
      <Divider my={30} />
      <YearBuiltFilter
        minYearBuilt={props?.minYearBuilt}
        maxYearBuilt={props?.maxYearBuilt}
        handleMinYearBuiltChange={props.handleMinYearBuiltChange}
        handleMaxYearBuiltChange={props.handleMaxYearBuiltChange}
        handleError={props.handleError}
        errors={props.errors}
      />
      <Divider my={30} />
      <SquareFootageFilter
        minSquareFootage={props?.minSquareFootage}
        maxSquareFootage={props?.maxSquareFootage}
        handleMinSquareFootageChange={props.handleMinSquareFootageChange}
        handleMaxSquareFootageChange={props.handleMaxSquareFootageChange}
        handleError={props.handleError}
        errors={props.errors}
      />
      <Divider my={30} />
      <LotSizeFilter
        minLotSize={props?.minLotSize}
        maxLotSize={props?.maxLotSize}
        handleMinLotSizeChange={props.handleMinLotSizeChange}
        handleMaxLotSizeChange={props.handleMaxLotSizeChange}
        handleError={props.handleError}
        errors={props.errors}
      />
    </>
  );
};
