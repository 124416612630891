/* storybook-check-ignore */
import { Box, Text } from '@opendoor/bricks-next';
import { formatCurrency } from '@opendoor/shared-fe/formatUtils';

import { PropertyDetailsFragment } from '__generated__/athena';

import { PricingBuyItNow, PricingTraditional } from './Pricing';

interface PropertySummaryProps {
  property: PropertyDetailsFragment;
}
export const MiniMapPropertySummary = ({ property }: PropertySummaryProps) => {
  return (
    <Box tag="hgroup" $largerThanMD={{ display: 'none' }}>
      <Text
        tag="h1"
        pb={24}
        color="$contentPrimary"
        fontWeight="$regular"
        typography="$subheaderXsmall"
      >
        <Text tag="span" typography="$subheaderXsmall" fontWeight="$regular">
          {property.street}
        </Text>
        <br />
        <Text tag="span" typography="$subheaderXsmall" fontWeight="$regular">
          {property.city}, {property.state} {property.zip}
        </Text>
      </Text>
      <Box tag="section">
        {property?.displayBadge === 'TRADITIONAL' ? (
          <PricingTraditional listPrice={property?.listPrice} />
        ) : (
          <PricingBuyItNow
            buyDirectPrice={property?.buyDirectPrice}
            listPrice={property?.listPrice}
          />
        )}
        <Box flexDirection="row">
          <Text tag="span" typography="$bodySmall" color="$contentSecondary">
            {property.bedrooms} bds
          </Text>
          <Text tag="span" typography="$bodySmall" color="$contentStateInactivePrimary" mx={4}>
            ·
          </Text>
          <Text tag="span" typography="$bodySmall" color="$contentSecondary">
            {property.bathrooms} ba
          </Text>
          <Text tag="span" typography="$bodySmall" color="$contentStateInactivePrimary" mx={4}>
            ·
          </Text>
          <Text tag="span" typography="$bodySmall" color="$contentSecondary">
            {property.sqFtTotalLiving} sqft
          </Text>
          <Text tag="span" typography="$bodySmall" color="$contentStateInactivePrimary" mx={4}>
            ·
          </Text>
          <Text tag="p" typography="$bodySmall" color="$contentSecondary">
            {property?.estimatedMonthlyPayment &&
              `${formatCurrency(property?.estimatedMonthlyPayment)}/month est.`}
          </Text>
        </Box>
        <Box flexDirection="row">
          <Text tag="span" typography="$bodySmall" color="$contentSecondary">
            {!!property?.schoolDistricts?.length && `${property?.schoolDistricts?.join(' & ')}`}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
