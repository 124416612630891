import { RangeOption } from './types';

/**
 * For use with Dropdown values and options to prevent invalid states in filter ranges
 *
 * @param options a list of RangeOptions for a Dropdown
 * @param op whether options should be accepted above or below the limit
 * @param limit a stringified integer representing the limit
 * @returns the filtered list of RangeOptionsZ
 */
export const filterIntOptions = (
  options: RangeOption[],
  op: 'gt' | 'lt',
  limit?: string,
): RangeOption[] => {
  if (!limit) {
    return options.slice();
  }

  const limitVal = parseInt(limit, 10);
  if (isNaN(limitVal)) {
    return options.slice();
  }

  return options.filter((option) => {
    const val = parseInt(option.value, 10);
    if (op === 'gt') {
      return val > limitVal;
    }

    return val < limitVal;
  });
};
