/* storybook-check-ignore */
import { useMemo, useState } from 'react';

import { Box, Button, Loader } from '@opendoor/bricks-next';

import { MarketplacePropertyListFragment } from '__generated__/athena';

import { ListingCard } from 'components/marketplace/property';

interface PropertyListProps {
  isLoading: boolean;
  handleOnSeeMore: () => void;
  propertyListData: MarketplacePropertyListFragment | null;
}

export const PropertyList = ({
  isLoading,
  handleOnSeeMore,
  propertyListData,
}: PropertyListProps) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const propertyCards = useMemo(() => {
    return propertyListData?.results?.map((listing: any, index: number) => {
      return (
        <Box
          key={index}
          mb={24}
          $largerThanXS={{ width: '100%' }}
          $largerThanSM={{ width: '100%' }}
          $largerThanMD={{ width: '48%' }}
          $largerThanLG={{ width: '48%' }}
        >
          <ListingCard listing={listing} />
        </Box>
      );
    });
  }, [propertyListData]);

  const onSeeMore = async () => {
    setIsLoadingMore(true);
    await handleOnSeeMore();
    setTimeout(() => setIsLoadingMore(false), 500);
  };

  return (
    <>
      <Box
        flexWrap="wrap"
        justifyContent="space-between"
        flexDirection="row"
        data-testid="map-property-list"
      >
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" width="100%">
            <Loader backgroundColor="$backgroundPrimary" size="large" />
          </Box>
        ) : (
          propertyCards
        )}
      </Box>
      {propertyListData?.nextPage && (
        <Button
          analyticsName="more-property-list"
          data-testid="map-more-property-list"
          label="Show more"
          onPress={onSeeMore}
          size="medium"
          variant="secondary"
          mb={30}
          loading={isLoadingMore}
        />
      )}
    </>
  );
};
