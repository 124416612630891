import { MutableRefObject, useEffect } from 'react';

export const useOutsideCaller = (cb: () => void, refs: MutableRefObject<HTMLElement | null>[]) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      let target: Node | null = null;
      if (event.target instanceof Node) {
        target = event.target;
      }

      if (refs.every((ref) => !ref.current || !ref.current.contains(target))) {
        cb();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs]);
};
