/* storybook-check-ignore */
import { Box, SimpleCheckbox, Text } from '@opendoor/bricks-next';

export interface HomeTypeFilterProps {
  singleFamily?: boolean | null;
  townhome?: boolean | null;
  condo?: boolean | null;
  handleSingleFamilyChange: (_: string, checked: string | boolean) => void;
  handleTownhomeChange: (_: string, checked: string | boolean) => void;
  handleCondoChange: (_: string, checked: string | boolean) => void;
}

export const HomeTypeFilter = ({
  singleFamily,
  townhome,
  condo,
  handleSingleFamilyChange,
  handleTownhomeChange,
  handleCondoChange,
}: HomeTypeFilterProps) => {
  return (
    <Box flexDirection="column" width="100%" justifyContent="space-between">
      <Text
        tag="p"
        typography="$labelXlarge"
        color="$contentPrimary"
        alignSelf="flex-start"
        mb={20}
      >
        Home type
      </Text>
      <Box flexDirection="row" justifyContent="space-between" alignItems="center" my={10}>
        <Text tag="p" typography="$bodyMedium" color="$contentPrimary">
          Single-family homes
        </Text>
        <SimpleCheckbox
          ariaLabel="single-family-home-checkbox"
          analyticsName="sfh-checkbox"
          id="single-family-home-checkbox"
          size="medium"
          name="singleFamily"
          value="true"
          defaultChecked={!!singleFamily}
          onPress={handleSingleFamilyChange}
        />
      </Box>
      <Box flexDirection="row" justifyContent="space-between" alignItems="center" my={10}>
        <Text tag="p" typography="$bodyMedium" color="$contentPrimary">
          Townhomes
        </Text>
        <SimpleCheckbox
          ariaLabel="townhome-checkbox"
          analyticsName="townhome-checkbox"
          id="townhome-checkbox"
          size="medium"
          name="townhome"
          value="true"
          defaultChecked={!!townhome}
          onPress={handleTownhomeChange}
        />
      </Box>
      <Box flexDirection="row" justifyContent="space-between" alignItems="center" mt={10}>
        <Text tag="p" typography="$bodyMedium" color="$contentPrimary">
          Condos
        </Text>
        <SimpleCheckbox
          ariaLabel="condo-checkbox"
          analyticsName="condo-checkbox"
          id="condo-checkbox"
          size="medium"
          name="condo"
          value="true"
          defaultChecked={!!condo}
          onPress={handleCondoChange}
        />
      </Box>
    </Box>
  );
};
