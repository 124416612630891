/* storybook-check-ignore */
import { Box, Dropdown, Text } from '@opendoor/bricks-next';

import { ErrorKey, RangeOption } from 'components/marketplace/search/types';

import { filterIntOptions } from './utils';

const YearBuiltOptions: RangeOption[] = [
  { label: '1900', value: '1900' },
  { label: '1920', value: '1920' },
  { label: '1940', value: '1940' },
  { label: '1960', value: '1960' },
  { label: '1980', value: '1980' },
  { label: '2000', value: '2000' },
  { label: '2005', value: '2005' },
  { label: '2010', value: '2010' },
  { label: '2015', value: '2015' },
  { label: '2016', value: '2016' },
  { label: '2017', value: '2017' },
  { label: '2018', value: '2018' },
  { label: '2019', value: '2019' },
  { label: '2020', value: '2020' },
  { label: '2021', value: '2021' },
  { label: '2022', value: '2022' },
  { label: '2023', value: '2023' },
  { label: '2024', value: '2024' },
];

export interface YearBuiltFilterProps {
  minYearBuilt?: string | null;
  maxYearBuilt?: string | null;
  handleMinYearBuiltChange: (value: string) => void;
  handleMaxYearBuiltChange: (value: string) => void;
  handleError: (key: ErrorKey, val: string) => void; // used to notify parent component of error state
  errors?: Record<ErrorKey, string> | null;
}

export const YearBuiltFilter = ({
  minYearBuilt,
  maxYearBuilt,
  handleMinYearBuiltChange,
  handleMaxYearBuiltChange,
  handleError,
  errors,
}: YearBuiltFilterProps) => {
  const onMinYearBuildChange = (value: string) => {
    if (maxYearBuilt && parseInt(value, 10) > parseInt(maxYearBuilt, 10)) {
      handleError('minYearBuilt', 'Min year built must be less than max year built');
      return;
    }
    handleError('minYearBuilt', '');
    handleMinYearBuiltChange(value);
  };

  const onMaxPriceChange = (value: string) => {
    if (minYearBuilt && parseInt(value, 10) < parseInt(minYearBuilt, 10)) {
      handleError('maxYearBuilt', 'Max year built must be greater than min year built');
      return;
    }
    handleError('maxYearBuilt', '');
    handleMaxYearBuiltChange(value);
  };

  const minOptions = filterIntOptions(YearBuiltOptions, 'lt', maxYearBuilt ?? undefined);
  const maxOptions = filterIntOptions(YearBuiltOptions, 'gt', minYearBuilt ?? undefined);

  return (
    <Box flexDirection="column" width="100%">
      <Text tag="p" typography="$labelXlarge">
        Year built
      </Text>
      <Box flexDirection="row" width="100%" justifyContent="space-between">
        <Box width="48%">
          <Dropdown
            title=" "
            placeholderOption={{
              label: 'No min',
              value: '0',
            }}
            data-testid="min-year-built-dropdown"
            options={minOptions}
            onValueChange={onMinYearBuildChange}
            size="small"
            prefilledValue={minYearBuilt || undefined}
            errorText={errors?.['minYearBuilt']}
          />
        </Box>
        <Box width="48%">
          <Dropdown
            title=" "
            placeholderOption={{
              label: 'No max',
              value: '500000000',
            }}
            data-testid="max-year-built-dropdown"
            options={maxOptions}
            onValueChange={onMaxPriceChange}
            size="small"
            prefilledValue={maxYearBuilt || undefined}
            errorText={errors?.['maxYearBuilt']}
          />
        </Box>
      </Box>
    </Box>
  );
};
