/* storybook-check-ignore */
import { Box, PressableBox, Text } from '@opendoor/bricks-next';

interface FloatingNavigationBarProps {
  activeTab: ActiveTab;
  handleOnViewBrowse?: () => void | null;
  handleOnViewFavorites?: () => void | null;
  shouldHide?: boolean | null;
}

export enum ActiveTab {
  Browse = 'browse',
  Favorites = 'favorites',
}

export const FloatingNavigationBar = ({
  activeTab,
  handleOnViewBrowse,
  handleOnViewFavorites,
  shouldHide = false,
}: FloatingNavigationBarProps) => {
  return (
    <Box
      $platform-web={{
        position: 'fixed',
      }}
      bottom={shouldHide ? -100 : 20}
      left={0}
      right={0}
      zIndex={2000}
      height={70}
      width={410}
      alignSelf="center"
      display="flex"
      flexDirection="row"
      justifyContent="center"
      margin="auto"
      mb={10}
      $smallerThanMD={{
        width: 210,
      }}
      backgroundColor="$backgroundPrimary"
      py={0}
      px={10}
      shadow="$default"
      borderRadius="$4x"
      style={{
        transition: 'bottom 0.25s',
      }}
    >
      <PressableBox
        aria-label="Browse"
        width={200}
        height={50}
        marginHorizontal="auto"
        padding={6}
        analyticsName="browse-toggle"
        data-testid="browse-toggle-button"
        alignSelf="center"
        onPress={handleOnViewBrowse}
        $smallerThanMD={{
          width: 100,
        }}
        backgroundColor={
          activeTab === ActiveTab.Browse ? '$backgroundInversePrimary' : '$backgroundPrimary'
        }
        borderBottomLeftRadius="$4x"
        borderBottomRightRadius={activeTab === ActiveTab.Browse ? '$4x' : '$2x'}
        borderTopRightRadius={activeTab === ActiveTab.Browse ? '$4x' : '$2x'}
        borderTopLeftRadius="$4x"
        py={30}
        shadow="$default"
        pressStyle={{
          backgroundColor: '$backgroundInversePrimary',
        }}
        hoverStyle={{
          backgroundColor:
            activeTab === ActiveTab.Browse ? '$backgroundInversePrimary' : '$backgroundTertiary',
        }}
        style={{
          transition: 'background-color 0.25s',
        }}
      >
        <Text
          color={activeTab === ActiveTab.Browse ? '$contentInverseTertiary' : '$contentTertiary'}
          tag="span"
          typography="$labelLarge"
        >
          Browse
        </Text>
      </PressableBox>
      <PressableBox
        aria-label="Favorites"
        width={200}
        height={50}
        marginHorizontal="auto"
        padding={6}
        analyticsName="favorites-toggle"
        data-testid="browfavoritesse-toggle-button"
        alignSelf="center"
        onPress={handleOnViewFavorites}
        backgroundColor={
          activeTab === ActiveTab.Favorites ? '$backgroundInversePrimary' : '$backgroundPrimary'
        }
        py={30}
        borderBottomLeftRadius={activeTab === ActiveTab.Favorites ? '$4x' : '$2x'}
        borderBottomRightRadius="$4x"
        borderTopLeftRadius={activeTab === ActiveTab.Favorites ? '$4x' : '$2x'}
        borderTopRightRadius="$4x"
        $smallerThanMD={{
          width: 100,
        }}
        shadow="$default"
        pressStyle={{
          backgroundColor: '$backgroundInversePrimary',
        }}
        hoverStyle={{
          backgroundColor:
            activeTab === ActiveTab.Favorites ? '$backgroundInversePrimary' : '$backgroundTertiary',
        }}
        style={{
          transition: 'background-color 0.25s',
        }}
      >
        <Text
          color={activeTab === 'favorites' ? '$contentInverseTertiary' : '$contentTertiary'}
          tag="span"
          typography="$labelLarge"
        >
          Favorites
        </Text>
      </PressableBox>
    </Box>
  );
};
