import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MapboxMap, MapRef, ViewStateChangeEvent } from 'react-map-gl';

import { useAuth } from '@opendoor/auth-fe';
import { Box, Button, Text } from '@opendoor/bricks-next';
import Spritesheet from '@opendoor/bricks/core/Icon/Spritesheet';
import { ClientError } from 'graphql-request';
import { useDebouncedCallback } from 'use-debounce';

import {
  AddressSearchResultFragment,
  GetMarketCoordinatesQuery,
  GetMarketplacePropertyListQuery,
  GetMarketplacePropertySearchQuery,
  MapMarkerDetailsFragment,
  MarketplacePropertyListFragment,
  MarketplacePropertySearchFragment,
  MarketplacePropertySearchResultFragment,
  OdProtosBuyerV2Data_Reaction,
  OdProtosCommonMarket_Market,
  PropertyDetailsFragment,
  PropertyType,
} from '__generated__/athena';

import { getAthenaClient } from 'components/api';
import { ActiveTab, FloatingNavigationBar } from 'components/marketplace/FloatingNavigationBar';
import { usePropertyReactions } from 'components/marketplace/helpers';
import {
  buildAddressPreviewParams,
  buildQueryParams,
  buildVerticesQueryParams,
  parseQueryParams,
  removeAddressPreviewParams,
  useSearchQueries,
} from 'components/marketplace/helpers/useSearchQueries';
import { MarketplaceMap } from 'components/marketplace/map/Map';
import { MapMarker } from 'components/marketplace/map/MapMarker';
import { MiniPropertyDetailPage } from 'components/marketplace/map/MiniPropertyDetailPage';
import { MobileHeaderFilters } from 'components/marketplace/map/MobileHeaderFilters';
import { MobileMiniPropertyDetailPage } from 'components/marketplace/map/MobileMiniPropertyDetailPage';
import { PropertyList } from 'components/marketplace/map/PropertyList';
import { QuickFilters } from 'components/marketplace/map/QuickFilters';
import { MapSearchFilters } from 'components/marketplace/map/SearchFilters';
import {
  MapLocation,
  MapMarkerProps,
  MarketInfo,
  TopMarker,
} from 'components/marketplace/map/types';
import { getShapeFromMap } from 'components/marketplace/map/utils';
import MarketplaceMapLayout from 'components/properties/shared/MarketplaceMapLayout';

import {
  CacheSafeGetServerSideProps,
  InferGetServerSidePropsType,
} from 'declarations/shared/serverSideProps';

import { useObservability } from '../../helpers/observability';

const NEW_CITY_SEARCH_ZOOM = 9;
const NEW_ZIP_SEARCH_ZOOM = 11;
const NEW_NEIGHBORHOOD_SEARCH_ZOOM = 13;

const Page: React.FC<InferGetServerSidePropsType<typeof getServerSideProps>> = ({
  propertyList,
  propertySearch,
  marketInfo,
  queryParams,
}) => {
  const { authentication, user } = useAuth();
  const { trackJourneyEvent } = useObservability();
  const isLoggedIn = authentication?.state === 'authenticated';
  const { propertyListAndSearch } = useSearchQueries();
  const smallMap = useRef<MapRef>(null);
  const mediumMap = useRef<MapRef>(null);
  const largeMap = useRef<MapRef>(null);

  // Query results
  const [propertySearchData, setPropertySearchData] =
    useState<MarketplacePropertySearchFragment>(propertySearch);
  const [propertyListData, setPropertyListData] = useState<MarketplacePropertyListFragment | null>(
    propertyList,
  );

  // Search filters/pagination
  const [minBeds, setBedrooms] = useState(queryParams?.bedrooms || 0);
  const [minBaths, setBathrooms] = useState(queryParams?.bathrooms || 0);
  const [canSelfTour, setCanSelfTour] = useState<boolean | null>(null);
  const [minPrice, setMinPrice] = useState(queryParams?.minListPrice || null);
  const [maxPrice, setMaxPrice] = useState(queryParams?.maxListPrice || null);
  const [singleFamily, setSingleFamily] = useState(true);
  const [condo, setCondo] = useState(true);
  const [townhome, setTownhome] = useState(true);
  const [noHoas, setNoHoas] = useState(queryParams?.noHoas || false);
  const [singleStory, setSingleStory] = useState(queryParams?.hasSingleStory || false);
  const [mustHavePool, setMustHavePool] = useState(queryParams?.hasPool || false);
  const [minYearBuilt, setMinYearBuilt] = useState(queryParams?.minYearBuilt || null);
  const [maxYearBuilt, setMaxYearBuilt] = useState(queryParams?.maxYearBuilt || null);
  const [minLotSize, setMinLotSize] = useState(queryParams?.minLotSize || null);
  const [maxLotSize, setMaxLotSize] = useState(queryParams?.maxLotSize || null);
  const [minSquareFootage, setMinSquareFootage] = useState(queryParams?.minSquareFootage || null);
  const [maxSquareFootage, setMaxSquareFootage] = useState(queryParams?.maxSquareFootage || null);
  const [pageNumber, setPageNumber] = useState(0);

  // UX states
  const [isLoadingResults, setIsLoadingResults] = useState(false);
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>();
  const [showList, setShowList] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [showPdp, setShowPdp] = useState<boolean>(false);
  const [propertyReactions, setPropertyReactions] = useState<
    Record<string, OdProtosBuyerV2Data_Reaction> | undefined
  >();
  const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.Browse);

  // Map marker information
  const markerDetailsCache = useRef(new Map<string, Promise<MapMarkerDetailsFragment | null>>());
  const [topMarker, setTopMarker] = useState<TopMarker | null>(null);

  // Location data
  const [vertices, setVertices] = useState(marketInfo.shape.coordinates[0]);
  const [selectedLocation, setSelectedLocation] = useState<MapLocation | undefined>();
  const [selectedAddress, setSelectedAddress] = useState<AddressSearchResultFragment | undefined>();

  // Individual property details
  const [propertyDetails, setPropertyDetails] = useState<
    PropertyDetailsFragment | undefined | null
  >();
  const { isLiked, onClickReaction } = usePropertyReactions(propertyDetails?.addressToken);
  const handleFetchPropertyDetails = useCallback(async (addressToken: string) => {
    const result = await getAthenaClient().GetMarketplacePropertyCoreDetails({ addressToken });
    setPropertyDetails(result.marketplace?.property);
    setShowFilters(false);
    setShowPdp(true);
  }, []);

  useEffect(() => {
    if (queryParams?.preview_aid) {
      handleFetchPropertyDetails(queryParams.preview_aid);
    }
  }, [queryParams?.preview_aid]);

  const flyTo = (location: MapLocation) => {
    let zoom = NEW_CITY_SEARCH_ZOOM;
    if (location.zoneType === '(Zip code)') {
      zoom = NEW_ZIP_SEARCH_ZOOM;
    } else if (location.zoneType === '(Neighborhood)') {
      zoom = NEW_NEIGHBORHOOD_SEARCH_ZOOM;
    }

    [smallMap, mediumMap, largeMap].forEach((map) => {
      map.current?.flyTo({
        center: location.coordinates,
        zoom,
      });
    });
  };

  const createMapMarker = (
    property: MarketplacePropertySearchResultFragment,
    idx: number,
  ): ReactElement<MapMarkerProps> | undefined => {
    const addressToken = property.addressToken;
    if (addressToken === null || addressToken === undefined) {
      return undefined;
    }

    const lat = property.location?.lat;
    if (lat === undefined) {
      return undefined;
    }

    const lon = property.location?.lon;
    if (lon === undefined) {
      return undefined;
    }

    const handleOnClick = () => {
      handleFetchPropertyDetails(addressToken);
      const urlParams = buildAddressPreviewParams(addressToken);
      window.history.pushState({}, '', `${window.location.pathname}?${urlParams}`);
    };

    const markerProperty = {
      ...property,
      addressToken,
      location: {
        lat,
        lon,
      },
      isLiked: propertyReactions?.[addressToken] === 'REACTION_LIKE',
    };

    let keySuffix = '';
    if (topMarker?.address === property.addressToken) {
      keySuffix = '-top';
    }

    return (
      <MapMarker
        key={`${property.addressToken}${keySuffix}`}
        property={markerProperty}
        index={idx}
        detailCache={markerDetailsCache}
        onClick={handleOnClick}
        moveToTop={(hasFocus: boolean) => {
          setTopMarker({ index: idx, address: addressToken, hasFocus });
        }}
        setFocused={topMarker?.hasFocus}
      />
    );
  };

  const mapMarkerIndex = useMemo(() => {
    setTopMarker(null);

    return (
      propertySearchData?.results
        ?.slice()
        .sort((a, b) => (b.location?.lat ?? 0) - (a.location?.lat ?? 0))
        ?.map<ReactElement<MapMarkerProps> | undefined>(createMapMarker)
        ?.filter<ReactElement<MapMarkerProps>>(
          (marker): marker is ReactElement<MapMarkerProps> => marker !== undefined,
        ) ?? []
    );
  }, [propertySearchData, propertyReactions]);

  const mapMarkers = useMemo(() => {
    const markers = [...mapMarkerIndex];

    if (topMarker !== null) {
      markers.splice(topMarker?.index, 1);

      // recreate this specific marker with a new key to re-render it
      const property = propertySearchData.results?.find(
        (property) => property.addressToken === topMarker?.address,
      );
      if (property) {
        const marker = createMapMarker(property, topMarker.index);
        if (marker) {
          markers.push(marker);
        }
      }
    }

    return markers;
  }, [mapMarkerIndex, topMarker]);

  const setPropertyResults = (
    searchResult: MarketplacePropertySearchFragment | null | undefined,
    listResult: MarketplacePropertyListFragment | null | undefined,
  ) => {
    if (searchResult) {
      setPropertySearchData(searchResult);
    }

    setPropertyListData(listResult ?? null);
  };

  const handleError = (key: string, val: string) => {
    if (val === '') {
      return setErrors((prev) => {
        if (prev && prev[key]) {
          delete prev[key];
          return { ...prev };
        }
        return;
      });
    }

    return setErrors((prev) => ({ ...prev, [key]: val }));
  };

  const getReactions = async () => {
    if (isLoggedIn && user?.customerUuid) {
      const reactions = await getAthenaClient().GetBuyerPropertyReactions({
        customerId: user.customerUuid,
      });
      const reducedReactions = reactions?.buyerService?.getBuyer?.buyer?.reactions?.reduce<
        Record<string, OdProtosBuyerV2Data_Reaction>
      >((reactions, reaction) => {
        reactions[reaction.addressId] = reaction.reaction;
        return reactions;
      }, {});
      setPropertyReactions(reducedReactions);
    }
  };

  useEffect(() => {
    if (marketInfo?.identifier) {
      const urlParams = buildQueryParams(queryParams);
      window.history.pushState({}, '', `/homes/${marketInfo.identifier}?${urlParams}`);
    }
  }, []);

  useEffect(() => {
    getReactions();
  }, [isLoggedIn]);

  useEffect(() => {
    window.addEventListener('propertyReactionEvent', getReactions);
    return () => window.removeEventListener('propertyReactionEvent', getReactions);
  }, []);

  // Track property view events
  useEffect(() => {
    if (showPdp && propertyDetails) {
      trackJourneyEvent('marketplaceProperty', 'detailPageView', {
        addressUuid: propertyDetails?.addressToken,
      });
    }
  }, [showPdp, propertyDetails]);

  // Close the PDP (if open) when a user taps on the browser's back button
  useEffect(() => {
    const handleBackButton = () => {
      hidePropertyDetails();
      const urlParams = removeAddressPreviewParams();
      window.history.pushState({}, '', `${window.location.pathname}?${urlParams}`);
    };

    window.addEventListener('popstate', handleBackButton);
    return () => window.removeEventListener('popstate', handleBackButton);
  }, []);

  // Smaller filter menu on desktop that allows for immediate querying
  const handleQuickFilterChange = async (bedrooms: string, bathrooms: string) => {
    setIsLoadingFilter(true);
    setIsLoadingResults(true);

    const { searchResult, listResult } = await propertyListAndSearch(
      {
        vertices,
        bedrooms: Number(bedrooms),
        bathrooms: Number(bathrooms),
        canSelfTour,
        minListPrice: minPrice,
        maxListPrice: maxPrice,
        minSquareFootage,
        maxSquareFootage,
        hasSingleStory: singleStory,
        hasPool: mustHavePool,
        singleFamily,
        condo,
        townhome,
        market: marketInfo.name,
        minYearBuilt,
        maxYearBuilt,
        zoneIds: selectedLocation?.value ? [selectedLocation.value] : null,
      },
      0,
    );

    setPropertyResults(
      searchResult.marketplace.propertySearch,
      listResult.marketplace.propertyList,
    );

    setIsLoadingFilter(false);
    setIsLoadingResults(false);
    handleSetBedrooms(bedrooms);
    handleSetBathrooms(bathrooms);
  };

  // Full filter menu that allows for more advanced filtering on desktop
  const handleOnFilterClick = async () => {
    setIsLoadingFilter(true);
    setIsLoadingResults(true);

    const { searchResult, listResult } = await propertyListAndSearch(
      {
        vertices,
        bedrooms: minBeds,
        bathrooms: minBaths,
        canSelfTour,
        minListPrice: minPrice,
        maxListPrice: maxPrice,
        minSquareFootage,
        maxSquareFootage,
        hasSingleStory: singleStory,
        hasPool: mustHavePool,
        singleFamily,
        condo,
        townhome,
        minYearBuilt,
        maxYearBuilt,
        market: marketInfo.name,
        zoneIds: selectedLocation?.value ? [selectedLocation.value] : null,
      },
      0,
    );

    setPropertyResults(
      searchResult?.marketplace?.propertySearch,
      listResult.marketplace?.propertyList,
    );

    setIsLoadingFilter(false);
    setIsLoadingResults(false);
  };

  // Mobile filter menu CTA click handler to trigger a search, close the filter menu, and fly to the selected location
  const handleMobileFilterClick = async () => {
    if (selectedLocation) {
      flyTo(selectedLocation);
    }
    await handleOnFilterClick();
    setShowFilters(false);
  };

  // Load more properties in the list view
  const handleOnSeeMore = () => {
    const propertyTypes: PropertyType[] = [];
    if (singleFamily) {
      propertyTypes.push('HOUSE' as PropertyType);
    }

    if (condo) {
      propertyTypes.push('CONDO' as PropertyType);
    }

    if (townhome) {
      propertyTypes.push('TOWNHOUSE' as PropertyType);
    }

    if (propertyListData?.nextPage) {
      getAthenaClient()
        .GetMarketplacePropertyList({
          market: marketInfo.name,
          page: pageNumber + 1,
          bedrooms: minBeds,
          bathrooms: minBaths,
          canSelfTour,
          minListPrice: minPrice,
          maxListPrice: maxPrice,
          minSquareFootage,
          maxSquareFootage,
          singleStoryOnly: singleStory,
          hasPool: mustHavePool,
          propertyTypes,
          minYearBuilt,
          maxYearBuilt,
          ...(vertices && { vertices }),
          ...(selectedLocation?.value && { zoneIds: [selectedLocation.value] }),
        })
        .then((result) => {
          const propertyList = result.marketplace.propertyList;
          if (!propertyList) {
            return;
          }

          setPropertyListData((prev) => {
            if (prev?.results) {
              return {
                results: [...prev.results, ...(propertyList?.results ?? [])],
                count: propertyList?.count ?? 0,
                nextPage: propertyList?.nextPage,
              };
            }

            return propertyList;
          });
          setPageNumber(pageNumber + 1);
        });
    }
  };

  const setSearchAreaFromMapBounds = useCallback(
    async (map: MapboxMap) => {
      setIsLoadingResults(true);
      const coordinates = getShapeFromMap(map);
      const queryParams = buildVerticesQueryParams(coordinates);
      window.history.replaceState({}, '', `${window.location.pathname}?${queryParams}`);
      setPropertyListData(null);
      const { searchResult, listResult } = await propertyListAndSearch(
        {
          bedrooms: minBeds,
          bathrooms: minBaths,
          canSelfTour,
          minListPrice: minPrice,
          maxListPrice: maxPrice,
          minSquareFootage,
          maxSquareFootage,
          hasSingleStory: singleStory,
          hasPool: mustHavePool,
          singleFamily,
          condo,
          townhome,
          minYearBuilt,
          maxYearBuilt,
          vertices: coordinates,
        },
        0,
      );

      setPropertyResults(
        searchResult.marketplace.propertySearch,
        listResult.marketplace.propertyList,
      );

      setVertices(coordinates);
      setIsLoadingFilter(false);
      setIsLoadingResults(false);
    },
    [
      setVertices,
      setPropertySearchData,
      setPropertyListData,
      marketInfo?.name,
      minBeds,
      minBaths,
      canSelfTour,
      minPrice,
      maxPrice,
      minSquareFootage,
      maxSquareFootage,
      singleStory,
      mustHavePool,
      singleFamily,
      condo,
      townhome,
      minYearBuilt,
      maxYearBuilt,
    ],
  );

  const hidePropertyDetails = useCallback(() => {
    setShowPdp(false);
  }, []);

  const debouncedSetSearchAreaFromViewport = useDebouncedCallback(setSearchAreaFromMapBounds, 1000);

  const handleOnMapMove = useCallback(
    (e: ViewStateChangeEvent) => {
      hidePropertyDetails();
      debouncedSetSearchAreaFromViewport(e.target);
    },
    [debouncedSetSearchAreaFromViewport, hidePropertyDetails],
  );

  const handleSetBedrooms = (value: string) => setBedrooms(Number(value));
  const handleSetBathrooms = (value: string) => setBathrooms(Number(value));
  const handleSetCanSelfTour = (value: string) => setCanSelfTour(value === 'true');
  const handleMinPriceChange = (minPrice: string) => setMinPrice(Number(minPrice) / 100);
  const handleMaxPriceChange = (maxPrice: string) => setMaxPrice(Number(maxPrice) / 100);
  const handleSingleFamilyChange = (_: string, checked: string | boolean) =>
    setSingleFamily(!!checked);
  const handleCondoChange = (_: string, checked: string | boolean) => setCondo(!!checked);
  const handleTownhomeChange = (_: string, checked: string | boolean) => setTownhome(!!checked);
  const handleNoHoasChange = (_: string, checked: string | boolean) => setNoHoas(!!checked);
  const handleSingleStoryChange = (_: string, checked: string | boolean) =>
    setSingleStory(!!checked);
  const handlePoolChange = (_: string, checked: string | boolean) => setMustHavePool(!!checked);
  const handleMinYearBuiltChange = (minYearBuilt: string) => setMinYearBuilt(Number(minYearBuilt));
  const handleMaxYearBuiltChange = (maxYearBuilt: string) => setMaxYearBuilt(Number(maxYearBuilt));
  const handleMinLotSizeChange = (minLotSize: string) => setMinLotSize(Number(minLotSize));
  const handleMaxLotSizeChange = (maxLotSize: string) => setMaxLotSize(Number(maxLotSize));
  const handleMinSquareFootageChange = (min: string) => setMinSquareFootage(Number(min));
  const handleMaxSquareFootageChange = (max: string) => setMaxSquareFootage(Number(max));

  const handleShowList = () => {
    setShowList(true);
  };
  const handleShowMap = () => setShowList(false);
  const onFiltersButtonClick = () => {
    setPropertyDetails(null);
    hidePropertyDetails();
    setShowList(false);
    setShowPdp(false);
    setShowFilters(true);
  };

  const handleSetSelectedLocation = async (location?: MapLocation | null) => {
    if (!location) {
      setSelectedLocation(undefined);
      return;
    }
    setIsLoadingResults(true);
    setIsLoadingFilter(true);
    setSelectedLocation(location);
    setVertices([]);
    setIsMobileHeaderFocused(false);
    flyTo(location);
    window.history.pushState({}, '', `/homes/zid_${location.value}`);
  };

  const handleSetSelectedAddress = async (address?: AddressSearchResultFragment | null) => {
    if (!address) {
      setSelectedAddress(undefined);
      return;
    }
    setSelectedAddress(address);
    handleFetchPropertyDetails(address.addressToken);
    setIsMobileHeaderFocused(false);
    const urlParams = buildAddressPreviewParams(address.addressToken);
    window.history.pushState({}, '', `${window.location.pathname}?${urlParams}`);
  };

  const handleResetFilters = () => {
    setBedrooms(0);
    setBathrooms(0);
    setCanSelfTour(null);
    setMinPrice(null);
    setMaxPrice(null);
    setSingleFamily(true);
    setCondo(true);
    setTownhome(true);
    setNoHoas(false);
    setSingleStory(false);
    setMustHavePool(false);
    setMinYearBuilt(null);
    setMaxYearBuilt(null);
    setMinLotSize(null);
    setMaxLotSize(null);
    setMinSquareFootage(null);
    setMaxSquareFootage(null);
  };

  const handleOnMobileFilterClick = () => {
    setShowList(false);
    setShowPdp(false);
    setShowFilters(true);
  };

  const [isMobileHeaderFocused, setIsMobileHeaderFocused] = useState(false);

  const handleOnMobileHeaderFocus = () => {
    setIsMobileHeaderFocused(true);
  };

  const handleOnMobileHeaderBlur = () => {
    setIsMobileHeaderFocused(false);
  };

  const handleOnViewFavorites = () => {
    setActiveTab(ActiveTab.Favorites);
    window.location.assign('/favorites');
  };

  return (
    <MarketplaceMapLayout>
      <Spritesheet />
      <Box
        $smallerThanMD={{ flexDirection: 'column' }}
        $largerThanSM={{ flexDirection: 'row' }}
        overflow="hidden"
        height="100%"
        width="100%"
        backgroundColor="$backgroundPrimary"
        position="relative"
        borderTopWidth="$1x"
        borderTopColor="$borderPrimary"
      >
        {/** Header */}
        <Box
          width={isMobileHeaderFocused ? '95%' : 200}
          height={60}
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          position="fixed"
          display="flex"
          top={5}
          zIndex={8000}
          left={isMobileHeaderFocused ? 10 : 55}
          onBlur={handleOnMobileHeaderBlur}
          onFocus={handleOnMobileHeaderFocus}
          style={{
            transition: 'width 0.25s, left 0.25s',
          }}
        >
          <Box width="100%" $largerThanSM={{ display: 'none' }} flexDirection="column">
            <MobileHeaderFilters
              isFocused={isMobileHeaderFocused}
              setSelectedAddress={handleSetSelectedAddress}
              selectedAddress={selectedAddress}
              setSelectedLocation={handleSetSelectedLocation}
              selectedLocation={selectedLocation}
              onBack={handleOnMobileHeaderBlur}
              onOpenFilters={handleOnMobileFilterClick}
            />
          </Box>
        </Box>
        {/** Fullscreen Map (Small) */}
        <Box
          $largerThanSM={{ display: 'none' }}
          position="relative"
          flexDirection="column"
          alignItems="center"
          height="100vh"
          width="100%"
          touchAction="none"
          borderTopWidth={1}
          borderTopColor="$borderPrimary"
          aria-hidden={showList || showFilters}
          opacity={showList || showFilters ? 0 : 1}
          style={{
            transition: showList || showFilters ? 'opacity 0.5s, visibility 0.5s' : 'opacity 0.5s',
            visibility: showList || showFilters ? 'hidden' : 'visible',
          }}
        >
          <MarketplaceMap
            mapRef={smallMap}
            handleOnClick={hidePropertyDetails}
            handleOnMapMove={handleOnMapMove}
            marketShape={marketInfo.shape}
            mapMarkers={mapMarkers}
          />
          <Button
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            position="fixed"
            bottom={80}
            width={80}
            marginHorizontal="auto"
            padding={6}
            analyticsName="list-view"
            label="List"
            data-testid="mobile-list-view"
            onPress={handleShowList}
            size="small"
            variant="secondaryInverse"
            mb={30}
            display={showList ? 'none' : 'flex'}
          />
        </Box>
        {/** Half-screen Map (Medium) */}
        <Box
          $smallerThanMD={{
            display: 'none',
          }}
          $largerThanMD={{
            display: 'none',
          }}
          flexBasis="50%"
          position="relative"
          flexDirection="column"
          alignItems="center"
          height="100%"
          width="100%"
          touchAction="none"
          borderTopWidth={1}
          borderTopColor="$borderPrimary"
          aria-hidden={showFilters}
          opacity={showFilters ? 0 : 1}
          style={{
            transition: showFilters ? 'opacity 0.5s, visibility 0.5s' : 'opacity 0.5s',
            visibility: showFilters ? 'hidden' : 'visible',
          }}
        >
          <MarketplaceMap
            mapRef={mediumMap}
            handleOnClick={hidePropertyDetails}
            handleOnMapMove={handleOnMapMove}
            marketShape={marketInfo.shape}
            mapMarkers={mapMarkers}
          />
        </Box>
        {/** Half-screen Map (Large) */}
        <Box
          $smallerThanLG={{
            display: 'none',
          }}
          flexBasis="50%"
          flex={1}
          position="relative"
          flexDirection="column"
          alignItems="center"
          height="100%"
          width="100%"
          touchAction="none"
          borderTopWidth={1}
          borderTopColor="$borderPrimary"
        >
          <MarketplaceMap
            mapRef={largeMap}
            handleOnClick={hidePropertyDetails}
            handleOnMapMove={handleOnMapMove}
            marketShape={marketInfo.shape}
            mapMarkers={mapMarkers}
          />
        </Box>
        {/** Fullscreen List (Small) */}
        <Box
          id="small-property-list"
          $largerThanSM={{ display: 'none' }}
          alignItems="center"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          overflowY="scroll"
          position="absolute"
          height="100%"
          width="100%"
          zIndex={3}
          px={20}
          backgroundColor="$backgroundPrimary"
          aria-hidden={!showList}
          opacity={showList ? 1 : 0}
          style={{
            transition: showList
              ? 'transform 0.5s ease, opacity 0.5s'
              : 'transform 0.5s ease, opacity 0.5s, visibility 0.5s',
            visibility: showList ? 'visible' : 'hidden',
            transform: showList ? '' : 'translateY(100vh)',
          }}
          marginHorizontal="auto"
          pb={130}
        >
          <Box width="100%" px={24} height="100%">
            <Box flexWrap="wrap" justifyContent="space-between" flexDirection="column" pb={52}>
              <Text tag="p" typography="$bodySmall" my={24}>
                {propertySearchData?.count} homes
              </Text>
              <PropertyList
                isLoading={isLoadingResults}
                handleOnSeeMore={handleOnSeeMore}
                propertyListData={propertyListData}
              />
            </Box>
          </Box>
          <Button
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            position="fixed"
            bottom={80}
            width={80}
            marginHorizontal="auto"
            padding={6}
            display={showList ? 'flex' : 'none'}
            analyticsName="list-view"
            data-testid="mobile-map-view"
            alignSelf="center"
            label="Map"
            onPress={handleShowMap}
            size="small"
            variant="secondaryInverse"
            mb={30}
          />
        </Box>
        {/** Side List (Medium/Large) */}
        <Box
          id="property-list-container"
          flexDirection="column"
          $smallerThanMD={{ display: 'none' }}
          backgroundColor="$backgroundPrimary"
          borderTopWidth={1}
          borderTopColor="$borderPrimary"
          flexBasis="50%"
          position="relative"
          aria-hidden={showPdp || showFilters}
          opacity={showPdp || showFilters ? 0 : 1}
          style={{
            transition: showPdp || showFilters ? 'opacity 0.5s, visibility 0.5s' : 'opacity 0.5s',
            visibility: showPdp || showFilters ? 'hidden' : 'visible',
          }}
        >
          <Box
            height="100%"
            px={48}
            pt={12}
            display="flex"
            width="100%"
            zIndex={1}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            overflowY="scroll"
            data-testid="desktop-property-list"
          >
            <QuickFilters
              bedrooms={minBeds}
              bathrooms={minBaths}
              selectedAddress={selectedAddress}
              setSelectedAddress={handleSetSelectedAddress}
              setSelectedLocation={handleSetSelectedLocation}
              selectedLocation={selectedLocation}
              onFilterChange={handleQuickFilterChange}
              isLoadingFilter={isLoadingFilter}
              onFiltersButtonClick={onFiltersButtonClick}
              isFocused={isMobileHeaderFocused}
            />
            <Text tag="p" typography="$bodySmall" mb={24}>
              {propertySearchData?.count} homes for sale near{' '}
              {selectedLocation?.label || marketInfo?.displayName || 'you'}
            </Text>
            <PropertyList
              isLoading={isLoadingResults}
              handleOnSeeMore={handleOnSeeMore}
              propertyListData={propertyListData}
            />
          </Box>
        </Box>
        {/** Fullscreen Filters (Small/Medium) */}
        <Box
          id="small-medium-filters"
          $largerThanMD={{ display: 'none' }}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          position="fixed"
          alignItems="center"
          height="100%"
          width="100%"
          zIndex={15000}
          top={-5}
          backgroundColor="$backgroundPrimary"
          aria-hidden={!showFilters}
          opacity={showFilters ? 1 : 0}
          style={{
            transition: showFilters
              ? 'transform 0.5s ease, opacity 0.5s'
              : 'transform 0.5s ease, opacity 0.5s, visibility 0.5s',
            visibility: showFilters ? 'visible' : 'hidden',
            transform: showFilters ? '' : 'translateY(-100vh)',
          }}
        >
          <MapSearchFilters
            minBeds={minBeds.toString()}
            minBaths={minBaths.toString()}
            canSelfTour={canSelfTour}
            minPrice={minPrice && (minPrice * 100)?.toString()}
            maxPrice={maxPrice && (maxPrice * 100)?.toString()}
            singleFamily={singleFamily}
            condo={condo}
            townhome={townhome}
            noHoas={noHoas}
            singleStory={singleStory}
            mustHavePool={mustHavePool}
            minYearBuilt={minYearBuilt}
            maxYearBuilt={maxYearBuilt}
            minLotSize={minLotSize}
            maxLotSize={maxLotSize}
            minSquareFootage={minSquareFootage?.toString()}
            maxSquareFootage={maxSquareFootage?.toString()}
            handleError={handleError}
            handleMinBedsChange={handleSetBedrooms}
            handleMinBathsChange={handleSetBathrooms}
            onCanSelfTourChange={handleSetCanSelfTour}
            handleMinSquareFootageChange={handleMinSquareFootageChange}
            handleMaxSquareFootageChange={handleMaxSquareFootageChange}
            handleMinPriceChange={handleMinPriceChange}
            handleMaxPriceChange={handleMaxPriceChange}
            handleSingleFamilyChange={handleSingleFamilyChange}
            handleCondoChange={handleCondoChange}
            handleTownhomeChange={handleTownhomeChange}
            handleNoHoasChange={handleNoHoasChange}
            handleSingleStoryChange={handleSingleStoryChange}
            handlePoolChange={handlePoolChange}
            handleMinYearBuiltChange={handleMinYearBuiltChange}
            handleMaxYearBuiltChange={handleMaxYearBuiltChange}
            handleMinLotSizeChange={handleMinLotSizeChange}
            handleMaxLotSizeChange={handleMaxLotSizeChange}
            onApplyFilters={handleMobileFilterClick}
            onResetFilters={handleResetFilters}
            onClose={() => setShowFilters(false)}
            isLoading={isLoadingResults}
            errors={errors}
          />
        </Box>
        {/** Side Filters (Large) */}
        <Box
          id="pdp-filters-container"
          flexDirection="column"
          $smallerThanLG={{ display: 'none' }}
          width="50%"
          height="100%"
          backgroundColor="$backgroundPrimary"
          borderTopWidth={1}
          borderTopColor="$borderPrimary"
          right={0}
          position="absolute"
          zIndex={2}
          aria-hidden={!showFilters}
          opacity={showFilters ? 1 : 0}
          style={{
            transition: showFilters
              ? 'transform 0.5s ease, opacity 0.5s'
              : 'transform 0.5s ease, opacity 0.5s, visibility 0.5s',
            visibility: showFilters ? 'visible' : 'hidden',
            transform: showFilters ? '' : 'translateX(50vh)',
          }}
          data-testid="desktop-filters"
        >
          <MapSearchFilters
            minBeds={minBeds.toString()}
            minBaths={minBaths.toString()}
            canSelfTour={canSelfTour}
            minPrice={minPrice && (minPrice * 100)?.toString()}
            maxPrice={maxPrice && (maxPrice * 100)?.toString()}
            singleFamily={singleFamily}
            condo={condo}
            townhome={townhome}
            noHoas={noHoas}
            singleStory={singleStory}
            mustHavePool={mustHavePool}
            minYearBuilt={minYearBuilt}
            maxYearBuilt={maxYearBuilt}
            minLotSize={minLotSize}
            maxLotSize={maxLotSize}
            minSquareFootage={minSquareFootage?.toString()}
            maxSquareFootage={maxSquareFootage?.toString()}
            handleError={handleError}
            handleMinBedsChange={handleSetBedrooms}
            handleMinBathsChange={handleSetBathrooms}
            onCanSelfTourChange={handleSetCanSelfTour}
            handleMinSquareFootageChange={handleMinSquareFootageChange}
            handleMaxSquareFootageChange={handleMaxSquareFootageChange}
            handleMinPriceChange={handleMinPriceChange}
            handleMaxPriceChange={handleMaxPriceChange}
            handleSingleFamilyChange={handleSingleFamilyChange}
            handleCondoChange={handleCondoChange}
            handleTownhomeChange={handleTownhomeChange}
            handleNoHoasChange={handleNoHoasChange}
            handleSingleStoryChange={handleSingleStoryChange}
            handlePoolChange={handlePoolChange}
            handleMinYearBuiltChange={handleMinYearBuiltChange}
            handleMaxYearBuiltChange={handleMaxYearBuiltChange}
            handleMinLotSizeChange={handleMinLotSizeChange}
            handleMaxLotSizeChange={handleMaxLotSizeChange}
            onApplyFilters={handleOnFilterClick}
            onResetFilters={handleResetFilters}
            onClose={() => setShowFilters(false)}
            isLoading={isLoadingResults}
            errors={errors}
          />
        </Box>
        {/** Mini PDP (Medium/Large) */}
        <Box
          flexDirection="column"
          $smallerThanMD={{ display: 'none' }}
          height="100%"
          width="50%"
          backgroundColor="$backgroundPrimary"
          borderTopColor="$borderPrimary"
          position="absolute"
          right={0}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          overflowY="scroll"
          zIndex={2}
          aria-hidden={!showFilters}
          opacity={showPdp ? 1 : 0}
          style={{
            transition: showPdp
              ? 'transform 0.5s ease, opacity 0.5s'
              : 'transform 0.5s ease, opacity 0.5s, visibility 0.5s',
            visibility: showPdp ? 'visible' : 'hidden',
            transform: showPdp ? '' : 'translateX(50vh)',
          }}
        >
          {propertyDetails && (
            <MiniPropertyDetailPage
              property={{
                ...propertyDetails,
                isLiked,
                onClickReaction,
              }}
              onClose={hidePropertyDetails}
              key={propertyDetails.addressToken}
            />
          )}
        </Box>
        {/** Mobile Mini PDP (Small) */}
        <Box
          $largerThanSM={{
            display: 'none',
          }}
          position={'absolute'}
          width="calc(100% - 20px)"
          marginHorizontal="$5x"
          height="75vh"
          backgroundColor="$backgroundPrimary"
          borderTopLeftRadius="$16x"
          borderTopRightRadius="$16x"
          overflow="hidden"
          zIndex={3000}
          aria-hidden={!showPdp}
          opacity={showPdp ? 1 : 0}
          bottom={0}
          style={{
            transition: showPdp
              ? 'transform 0.5s ease, opacity 0.5s'
              : 'transform 0.5s ease, opacity 0.5s, visibility 0.5s',
            visibility: showPdp ? 'visible' : 'hidden',
            transform: showPdp ? '' : 'translateY(75vh)',
          }}
        >
          {propertyDetails && (
            <MobileMiniPropertyDetailPage
              property={propertyDetails}
              onClose={hidePropertyDetails}
            />
          )}
        </Box>
      </Box>
      {/** Toggle Browse/Favorites */}
      <FloatingNavigationBar
        handleOnViewFavorites={handleOnViewFavorites}
        activeTab={activeTab}
        shouldHide={showPdp}
      />
    </MarketplaceMapLayout>
  );
};

const convertToGeoPointVertices = (vertices: number[][][]): { lon: number; lat: number }[][] => {
  return vertices?.map((polygon) => polygon.map(([lon, lat]) => ({ lon, lat }))) ?? [];
};

const DEFAULT_MARKET = 'PHOENIX';
export const getServerSideProps: CacheSafeGetServerSideProps<{
  propertyList: MarketplacePropertyListFragment;
  marketInfo: MarketInfo;
  propertySearch: MarketplacePropertySearchFragment;
  queryParams: Record<string, any>;
}> = async (context) => {
  let market = '';
  let zoneId;
  const queryParams = parseQueryParams(context.query);
  const marketParam = context.query['market'];
  if (!marketParam) {
    market = DEFAULT_MARKET;
  } else if (typeof marketParam === 'string' && marketParam.toLowerCase().indexOf('zid_') >= 0) {
    zoneId = marketParam.replace('zid_', '');
  } else if (Array.isArray(marketParam)) {
    market = marketParam[0];
  } else {
    market = marketParam;
  }

  let propertyListQuery: GetMarketplacePropertyListQuery | undefined;
  let propertySearchQuery: GetMarketplacePropertySearchQuery | undefined;
  let marketCoordinatesQuery: GetMarketCoordinatesQuery | undefined;
  let zoneQuery;

  // Default to Phoenix
  // TODO: This closure is referencing variables defined in outer scope.
  // This is intentional for now, but we should come up with a better way to handle this.
  const marketFallbackQuery = async () => {
    market = DEFAULT_MARKET;
    queryParams.market = market.toLowerCase();
    marketCoordinatesQuery = await getAthenaClient(context.req.headers).GetMarketCoordinates({
      market: market as OdProtosCommonMarket_Market,
    });
  };

  if (market) {
    try {
      marketCoordinatesQuery = await getAthenaClient(context.req.headers).GetMarketCoordinates({
        market: market.toUpperCase() as OdProtosCommonMarket_Market,
      });
      const isBuyingExperienceActive =
        marketCoordinatesQuery?.buyer?.getMarketConfigByMarket?.marketConfig?.buyingExperience;
      if (!isBuyingExperienceActive) {
        await marketFallbackQuery();
      }
    } catch (e) {
      if (e instanceof ClientError) {
        await marketFallbackQuery();
        // send to sentry
      } else {
        return {
          notFound: true,
        };
      }
    }
  }

  if (zoneId) {
    try {
      zoneQuery = await getAthenaClient(context.req.headers).GetZoneData({ zoneId });
    } catch (e) {
      if (e instanceof ClientError) {
        // eslint-disable-next-line no-console
        console.error('ClientError: ', e);
        if (!e.response.data?.geoDataStore?.getZones) {
          return {
            notFound: true,
          };
        }
        // send to sentry
      } else {
        return {
          notFound: true,
        };
      }
    }
  }

  try {
    propertyListQuery = await getAthenaClient(context.req.headers).GetMarketplacePropertyList({
      page: 0,
      bathrooms: queryParams?.bathrooms ? Number(queryParams?.bathrooms) : 0,
      bedrooms: queryParams?.bedrooms ? Number(queryParams?.bedrooms) : 0,
      minListPrice: queryParams?.minListPrice ? Number(queryParams?.minListPrice) : null,
      maxListPrice: queryParams?.maxListPrice ? Number(queryParams?.maxListPrice) : null,
      minSquareFootage: queryParams?.minSquareFootage
        ? Number(queryParams?.minSquareFootage)
        : null,
      maxSquareFootage: queryParams?.maxSquareFootage
        ? Number(queryParams?.maxSquareFootage)
        : null,
      singleStoryOnly: queryParams?.hasSingleStory || false,
      hasPool: queryParams?.hasPool || false,
      minYearBuilt: queryParams?.minYearBuilt ? Number(queryParams?.minYearBuilt) : null,
      maxYearBuilt: queryParams?.maxYearBuilt ? Number(queryParams?.maxYearBuilt) : null,
      ...((queryParams?.vertices?.length ?? 0) > 0 && { vertices: queryParams.vertices }),
      ...(zoneId && { zoneIds: [zoneId] }),
      ...(market && { market: market?.toLowerCase() as string }),
    });
  } catch (e) {
    if (e instanceof ClientError) {
      // eslint-disable-next-line no-console
      console.error('ClientError: ', e);
      if (!e.response.data?.marketplace?.propertyList) {
        return {
          notFound: true,
        };
      }
      // send to sentry
    } else {
      return {
        notFound: true,
      };
    }
  }

  try {
    propertySearchQuery = await getAthenaClient(context.req.headers).GetMarketplacePropertySearch({
      bathrooms: queryParams?.bathrooms ? Number(queryParams?.bathrooms) : 0,
      bedrooms: queryParams?.bedrooms ? Number(queryParams?.bedrooms) : 0,
      minListPrice: queryParams?.minListPrice ? Number(queryParams?.minListPrice) : null,
      maxListPrice: queryParams?.maxListPrice ? Number(queryParams?.maxListPrice) : null,
      minSquareFootage: queryParams?.minSquareFootage
        ? Number(queryParams?.minSquareFootage)
        : null,
      maxSquareFootage: queryParams?.maxSquareFootage
        ? Number(queryParams?.maxSquareFootage)
        : null,
      singleStoryOnly: queryParams?.hasSingleStory || false,
      hasPool: queryParams?.hasPool || false,
      minYearBuilt: queryParams?.minYearBuilt ? Number(queryParams?.minYearBuilt) : null,
      maxYearBuilt: queryParams?.maxYearBuilt ? Number(queryParams?.maxYearBuilt) : null,
      ...((queryParams?.vertices?.length ?? 0) > 0 && { vertices: queryParams.vertices }),
      ...(zoneId && { zoneIds: [zoneId] }),
      ...(market && { market: market?.toLowerCase() as string }),
    });
  } catch (e) {
    if (e instanceof ClientError) {
      // eslint-disable-next-line no-console
      console.error('ClientError: ', e);
      if (!e.response.data?.marketplace?.propertySearch) {
        return {
          notFound: true,
        };
      }
      // send to sentry
    } else {
      return {
        notFound: true,
      };
    }
  }

  const marketConfig = marketCoordinatesQuery?.buyer?.getMarketConfigByMarket?.marketConfig;

  let marketInfo: MarketInfo | undefined;
  if (zoneQuery?.geoDataStore?.getZones?.zones.length) {
    const zone = zoneQuery.geoDataStore.getZones.zones[0];
    const shape = JSON.parse(zone.boundingBox);
    const coordinates = convertToGeoPointVertices(shape.coordinates);
    marketInfo = {
      shape: {
        coordinates,
        type: 'Polygon',
      },
      displayName: zone.name,
      name: zone.name,
      identifier: null,
    };
  }

  if ((queryParams?.vertices.length ?? 0) > 0) {
    marketInfo = {
      shape: {
        coordinates: [queryParams.vertices],
        type: 'Polygon',
      },
      displayName: marketConfig?.displayName || marketInfo?.displayName || '',
      name: market,
      identifier: marketConfig?.identifier || null,
    };
  } else if (marketConfig?.shape) {
    const shape = JSON.parse(marketConfig.shape);
    const coordinates = convertToGeoPointVertices(shape.coordinates);
    marketInfo = {
      shape: {
        coordinates,
        type: shape.type,
      },
      displayName: marketConfig?.displayName ?? '',
      name: market,
      identifier: marketConfig?.identifier || null,
    };
  }

  if (!marketInfo) {
    return {
      notFound: true,
    };
  }

  const propertyList = propertyListQuery?.marketplace.propertyList;
  if (!propertyList) {
    return {
      notFound: true,
    };
  }

  const propertySearch = propertySearchQuery?.marketplace.propertySearch;
  if (!propertySearch) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      propertyList,
      marketInfo,
      propertySearch,
      queryParams,
    },
  };
};

export default Page;
