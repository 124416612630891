/* storybook-check-ignore */
import { Box, Dropdown, Text } from '@opendoor/bricks-next';

import { ErrorKey, RangeOption } from 'components/marketplace/search/types';

import { filterIntOptions } from './utils';

const PriceRangeOptions: RangeOption[] = [
  {
    label: '$100,000',
    value: '10000000',
  },
  {
    label: '$200,000',
    value: '20000000',
  },
  {
    label: '$300,000',
    value: '30000000',
  },
  {
    label: '$400,000',
    value: '40000000',
  },
  {
    label: '$500,000',
    value: '50000000',
  },
  {
    label: '$600,000',
    value: '60000000',
  },
  {
    label: '$700,000',
    value: '70000000',
  },
  {
    label: '$800,000',
    value: '80000000',
  },
  {
    label: '$900,000',
    value: '90000000',
  },
  {
    label: '$1,000,000',
    value: '100000000',
  },
  {
    label: '$1,250,000',
    value: '125000000',
  },
  {
    label: '$1,500,000',
    value: '150000000',
  },
  {
    label: '$1,750,000',
    value: '175000000',
  },
];

export interface PriceFilterProps {
  minPrice?: string | null;
  maxPrice?: string | null;
  handleMinPriceChange: (value: string) => void;
  handleMaxPriceChange: (value: string) => void;
  handleError: (key: ErrorKey, val: string) => void; // used to notify parent component of error state
  errors?: Record<ErrorKey, string> | null;
}

export const PriceFilter = ({
  minPrice,
  maxPrice,
  handleMinPriceChange,
  handleMaxPriceChange,
  handleError,
  errors,
}: PriceFilterProps) => {
  const onMinPriceChange = (value: string) => {
    if (maxPrice && parseInt(value, 10) > parseInt(maxPrice, 10)) {
      handleError('minPrice', 'Min price must be less than max price');
      return;
    }
    handleError('minPrice', '');
    handleMinPriceChange(value);
  };

  const onMaxPriceChange = (value: string) => {
    if (minPrice && parseInt(value, 10) < parseInt(minPrice, 10)) {
      handleError('maxPrice', 'Max price must be greater than min price');
      return;
    }
    handleError('maxPrice', '');
    handleMaxPriceChange(value);
  };

  const minOptions = filterIntOptions(PriceRangeOptions, 'lt', maxPrice ?? undefined);
  const maxOptions = filterIntOptions(PriceRangeOptions, 'gt', minPrice ?? undefined);

  return (
    <Box flexDirection="column" width="100%">
      <Text tag="p" typography="$labelXlarge">
        Price range
      </Text>
      <Box flexDirection="row" width="100%" justifyContent="space-between">
        <Box width="48%">
          <Dropdown
            id="price-filter-min"
            data-testid="price-filter-min"
            title=" "
            placeholderOption={{
              label: 'No min',
              value: '0',
            }}
            options={minOptions}
            onValueChange={onMinPriceChange}
            size="small"
            prefilledValue={minPrice || undefined}
            errorText={errors?.['minPrice']}
          />
        </Box>
        <Box width="48%">
          <Dropdown
            title=" "
            id="price-filter-max"
            placeholderOption={{
              label: 'No max',
              value: '500000000',
            }}
            options={maxOptions.concat({
              label: '$2,000,000+',
              value: '500000000',
            })}
            onValueChange={onMaxPriceChange}
            size="small"
            prefilledValue={maxPrice || undefined}
            errorText={errors?.['maxPrice']}
          />
        </Box>
      </Box>
    </Box>
  );
};
