/* storybook-check-ignore */
import { useState } from 'react';

import { Box, Button } from '@opendoor/bricks-next';

import { PropertyDetailsFragment } from '__generated__/athena';

import { removeAddressPreviewParams } from 'components/marketplace/helpers/useSearchQueries';
import { PhotoCarousel as MapPhotoCarousel } from 'components/marketplace/map/PhotoCarousel';
import { ShareModal } from 'components/marketplace/property';
import { MiniMapPropertySummary } from 'components/marketplace/property/PropertySection/MiniMapPropertySummary';

import { MOBILE_CAROUSEL_HEIGHT_MD, MOBILE_CAROUSEL_HEIGHT_SM } from './types';

interface MiniPropertyDetailPageProps {
  property: PropertyDetailsFragment;
  onClose: () => void;
}
const SWIPE_THRESHOLD = 50;

export const MobileMiniPropertyDetailPage = ({
  property,
  onClose,
}: MiniPropertyDetailPageProps) => {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [swipeStartY, setSwipeStartY] = useState(null);
  const handleTouchStart = (e: any) => {
    const touch = e.touches[0];
    setSwipeStartY(touch.clientY);
  };

  const handleOnClose = () => {
    const urlParams = removeAddressPreviewParams();
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
    onClose();
  };

  const handleToggleShareModal = () => {
    setIsShareModalOpen((prev) => !prev);
  };

  // Handle the end of the touch and check if it's a swipe down
  const handleTouchEnd = (e: any) => {
    if (swipeStartY === null) return;

    const touch = e.changedTouches[0];
    const swipeDistance = touch.clientY - swipeStartY;

    if (swipeDistance > SWIPE_THRESHOLD) {
      handleOnClose();
    }
    setSwipeStartY(null);
  };

  const handleOnClick = () => {
    window.location.href = `/properties/${property?.slug}/aid_${property?.addressToken}`;
  };

  return (
    <Box>
      <Box
        backgroundColor="$backgroundPrimary"
        width="100%"
        borderTopLeftRadius="$16x"
        borderTopRightRadius="$16x"
        height="75vh"
        flexDirection="column"
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        key={`mobile-mini-pdp-${property?.addressToken}`}
        exitStyle={{ height: '0vh' }}
        animation="accelerateSlow"
        touchAction="none"
      >
        <Box
          $smallerThanMD={{
            display: 'none',
          }}
          $largerThanSM={{
            height: MOBILE_CAROUSEL_HEIGHT_MD,
          }}
          mb={24}
        >
          <MapPhotoCarousel
            key={`med-mobile-carousel-${property?.addressToken}`}
            photos={property?.photos}
            onClose={handleOnClose}
            onOpenShareModal={handleToggleShareModal}
            isRounded
            height={MOBILE_CAROUSEL_HEIGHT_MD}
            onPhotoClick={handleOnClick}
          />
        </Box>
        <Box
          $smallerThanMD={{
            height: MOBILE_CAROUSEL_HEIGHT_SM,
          }}
          $largerThanSM={{
            display: 'none',
          }}
          mb={24}
        >
          <MapPhotoCarousel
            key={`sm-mobile-carousel-${property?.addressToken}`}
            photos={property?.photos}
            onClose={handleOnClose}
            onOpenShareModal={handleToggleShareModal}
            isRounded
            height={MOBILE_CAROUSEL_HEIGHT_SM}
            onPhotoClick={handleOnClick}
          />
        </Box>
        <Box px={20}>
          <MiniMapPropertySummary property={property} />
          <Button
            analyticsName="mobile-map-pdp-link"
            href={`/properties/${property?.slug}/aid_${property?.addressToken}`}
            size="small"
            variant="secondary"
            label="View home"
            mt={10}
          />
        </Box>
      </Box>
      <ShareModal property={property} isOpen={isShareModalOpen} onClose={handleToggleShareModal} />
    </Box>
  );
};
