/* storybook-check-ignore */
import { ReactElement, useCallback, useMemo, useState } from 'react';
import {
  GeolocateControl,
  Map,
  MapRef,
  NavigationControl,
  ViewStateChangeEvent,
} from 'react-map-gl';

import { getBoundsFromShape } from 'components/marketplace/map/utils';

import { MAPBOX_ACCESS_TOKEN, MAPBOX_MAP_STYLE } from 'helpers/globals';

import { MapMarkerProps, MarketShape } from './types';

const MIN_ZOOM = 7;
const MAX_ZOOM = 17;

interface MarketplaceMapProps {
  center?: number[];
  dragPan?: boolean;
  marketShape?: MarketShape;
  handleOnClick: () => void;
  handleOnMapMove: (e: ViewStateChangeEvent) => void;
  mapMarkers: ReactElement<MapMarkerProps>[];
  mapRef: React.RefObject<MapRef>;
  showGeoLocationControls?: boolean | null;
  zoom?: number;
}

export const MarketplaceMap = ({
  center,
  dragPan = true,
  marketShape,
  handleOnClick,
  handleOnMapMove,
  showGeoLocationControls = true,
  mapMarkers,
  mapRef,
  zoom,
}: MarketplaceMapProps) => {
  const [cursor, setCursor] = useState<'pointer' | 'grab' | 'grabbing'>('grab');

  const handleMouseEnter = useCallback(() => setCursor('pointer'), []);
  const handleMouseLeave = useCallback(() => setCursor('grab'), []);
  const handleMouseDown = useCallback(() => setCursor('grabbing'), []);
  // This means that if the user clicks on a cluster and the map re-renders with
  // a new cluster under their cursor, they will incorrectly see the "grab"
  // cursor rather than "grabbing", but it's much more efficient than monitoring
  // "mousemove" and continually polling for map features under the cursor
  const handleMouseUp = useCallback(() => setCursor('grab'), []);

  const initialViewState:
    | { bounds: { lng: number; lat: number }[] }
    | { longitude: number; latitude: number; zoom: number } = useMemo(
    () => {
      if (center) {
        return { longitude: center[1], latitude: center[0], zoom: zoom || MIN_ZOOM };
      } else if (marketShape) {
        return { bounds: getBoundsFromShape(marketShape) };
      }
      throw new Error(
        'MarketplaceMap: Must provide either center, initialCoordinates, or marketShape',
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on mount/unmount
    [],
  );

  return (
    <Map
      ref={mapRef}
      mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
      mapStyle={MAPBOX_MAP_STYLE}
      onMove={handleOnMapMove}
      interactiveLayerIds={[]}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      cursor={cursor}
      style={{ width: '100%', height: '100vh' }}
      minZoom={zoom || MIN_ZOOM}
      maxZoom={MAX_ZOOM}
      initialViewState={initialViewState as any}
      fadeDuration={0}
      onClick={handleOnClick}
      reuseMaps
      dragPan={dragPan}
    >
      <NavigationControl position="top-left" showCompass={false} />
      {showGeoLocationControls && <GeolocateControl position="top-right" />}
      {mapMarkers}
    </Map>
  );
};
