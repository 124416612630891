import { MutableRefObject } from 'react';

import {
  MapMarkerDetailsFragment,
  MarketplacePropertySearchResultFragment,
} from '__generated__/athena';

export type MapMarkerProperty = Omit<
  MarketplacePropertySearchResultFragment,
  'addressToken' | 'location'
> & {
  addressToken: string;
  isLiked: boolean;
  location: {
    lat: number;
    lon: number;
  };
};

export type MapLocation = {
  label: string;
  zoneType?: string;
  value?: string;
  coordinates?: [number, number];
  population?: number;
};

export interface GeoPoint {
  lon: number;
  lat: number;
}

export interface MarketShape {
  coordinates: GeoPoint[][];
  type: string;
}

export interface MarketInfo {
  shape: MarketShape;
  displayName: string;
  name: string;
  identifier?: string | null;
}

export interface TopMarker {
  index: number;
  address: string;
  hasFocus: boolean;
}

export interface MapMarkerProps {
  property: MapMarkerProperty;
  index: number;
  detailCache: MutableRefObject<Map<string, Promise<MapMarkerDetailsFragment | null>>>;
  onClick: () => void;
  moveToTop?: (hasFocus: boolean) => void;
  setFocused?: boolean;
}

export const MOBILE_CAROUSEL_HEIGHT_SM = 260;
export const MOBILE_CAROUSEL_HEIGHT_MD = 414;
