import { MapboxMap } from 'react-map-gl';

import { GeoPoint, MarketShape } from './types';

export const getBoundsFromShape = (shape: MarketShape): { lng: number; lat: number }[] => {
  const type = shape.type;
  const coordinates = shape.coordinates;

  let polygons;
  if (type === 'Polygon') {
    polygons = coordinates[0];
  } else if (type === 'MultiPolygon') {
    polygons = coordinates.flat(2);
  } else {
    throw new Error(`Unsupported GeoJSON shape type: ${type}`);
  }

  const longitudes = polygons.map((coord) => coord.lon);
  const latitudes = polygons.map((coord) => coord.lat);

  const minLat = Math.min(...latitudes);
  const minLng = Math.min(...longitudes);
  const maxLat = Math.max(...latitudes);
  const maxLng = Math.max(...longitudes);

  return [
    {
      lng: minLng,
      lat: minLat,
    },
    {
      lng: maxLng,
      lat: maxLat,
    },
  ];
};

export const getShapeFromMap = (map: MapboxMap): GeoPoint[] => {
  const bounds = map.getBounds();
  const vertices = [
    { lon: bounds.getNorthWest().lng, lat: bounds.getNorthWest().lat },
    { lon: bounds.getNorthEast().lng, lat: bounds.getNorthEast().lat },
    { lon: bounds.getSouthEast().lng, lat: bounds.getSouthEast().lat },
    { lon: bounds.getSouthWest().lng, lat: bounds.getSouthWest().lat },
    // The first and last points of a polygon must be identical
    { lon: bounds.getNorthWest().lng, lat: bounds.getNorthWest().lat },
  ];
  return vertices;
};
